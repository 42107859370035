/* Font Family */
$Font-Family  : (
    "Default"   : "GT Walsheim"
);
/* Font Size */
$Font-Size      : (
    "One"       : 1px,
    "Two"       : 2px,
    "Three"     : 3px,
    "Four"      : 4px,
    "Five"      : 5px,
    "Six"       : 6px,
    "Seven"     : 7px,
    "Eight"     : 8px,
    "Nine"      : 9px,
    "Ten"       : 10px,
    "Eleven"    : 11px,
    "Twelve"    : 12px,
    "Thirteen"  : 13px,
    "Fourteen"  : 14px,
    "Fiveteen"  : 15px,
    "Sixteen"   : 16px,
    "Seventeen" : 17px,
    "Eighteen"  : 18px,
    "Nineteen"  : 19px,
    "Twenty"    : 20px
);
/* Color */
$Color : (
    "Default" : #EB1E25,
    "White"   : #FFFFFF,
    "Black"   : #1F384B,
    "Dark"    : #3E3F41,
    "Grey"    : #B2B2B3,
    "Gray"    : #6F6F6F,
    "Light"   : #F1F1F1
);
/* 
    ---------- 
*/
.g1abzu{ float: left }
.h5tmdd{ float: right }
.cg5y7w{ float: none }
/* 
    ----------
*/
.gs2k2x{ width: 25% }
.w5z50s{ width: 50% }
.m1p4gu{ width: 75% }
.a7j6j1{ width: 100% }
/*
    ----------
*/
.os3dt5{ width: 1200px }
/* 
    ----------
*/
.g593tp{
    font-family: map-get( $Font-Family, "Default" );
}
/* 
    ----------
*/
.h82dhf{ font-size: map-get( $Font-Size, "One" ) }
.h69z1p{ font-size: map-get( $Font-Size, "Two" ) }
.we0qeb{ font-size: map-get( $Font-Size, "Three" ) }
.ac5zaa{ font-size: map-get( $Font-Size, "Four" ) }
.kh28hm{ font-size: map-get( $Font-Size, "Five" ) }
.kjbo4w{ font-size: map-get( $Font-Size, "Six" ) }
.t8yusu{ font-size: map-get( $Font-Size, "Seven" ) }
.b1t3y8{ font-size: map-get( $Font-Size, "Eight" ) }
.plz1it{ font-size: map-get( $Font-Size, "Nine" ) }
.m6x4jz{ font-size: map-get( $Font-Size, "Ten" ) }
.gg1c94{ font-size: map-get( $Font-Size, "Eleven" ) }
.ho0cv1{ font-size: map-get( $Font-Size, "Twelve" ) }
.r8q4zo{ font-size: map-get( $Font-Size, "Thirteen" ) }
.hpx6vm{ font-size: map-get( $Font-Size, "Fourteen" ) }
.djq82r{ font-size: map-get( $Font-Size, "Fiveteen" ) }
.flhu41{ font-size: map-get( $Font-Size, "Sixteen" ) }
.uu8xpu{ font-size: map-get( $Font-Size, "Seventeen" ) }
.vbv58q{ font-size: map-get( $Font-Size, "Eighteen" ) }
.ay35r3{ font-size: map-get( $Font-Size, "Nineteen" ) }
.ny62tq{ font-size: map-get( $Font-Size, "Twenty" ) }
/* 
    ----------
*/
.gj99yt{ font-weight: 100 }
.qj3cai{ font-weight: 200 }
.au2qgk{ font-weight: 300 }
.l9kha6{ font-weight: 400 }
.ju7ot2{ font-weight: 500 }
.jjp8x6{ font-weight: 600 }
.b6zmr2{ font-weight: 700 }
.z4ctyy{ font-weight: normal }
.fpmx0m{ font-weight: bold }
/* 
    ----------
*/
.r7fz53{ font-style: normal }
.u107lw{ font-style: italic }
/* 
    ----------
*/
.yc0r22{ color: map-get( $Color, "Default" ) }
.od5yo1{ color: map-get( $Color, "White" ) }
.hx2bzw{ color: map-get( $Color, "Black" ) }
.vwei3j{ color: map-get( $Color, "Dark" ) }
.px0inf{ color: map-get( $Color, "Grey" ) }
.n25600{ color: map-get( $Color, "Gray" ) }
.pl7byf{ color: map-get( $Color, "Light" ) }
/* 
    ----------
*/
.ke7ifl{ fill: map-get( $Color, "Default" ) }
.mo9q0r{ fill: map-get( $Color, "White" ) }
.ctj6s0{ fill: map-get( $Color, "Black" ) }
.lk1jon{ fill: map-get( $Color, "Dark" ) }
.auu562{ fill: map-get( $Color, "Grey" ) }
.x1n56d{ fill: map-get( $Color, "Gray" ) }
.pp4bd0{ fill: map-get( $Color, "Light" ) }
/* 
    ----------
 */
.z82y3r{ background: map-get( $Color, "Default" ) }
.os7ol5{ background: map-get( $Color, "White" ) }
.mzm3ni{ background: map-get( $Color, "Black" ) }
.o3u62u{ background: map-get( $Color, "Dark" ) }
.kjx24w{ background: map-get( $Color, "Grey" ) }
.h240sl{ background: map-get( $Color, "Gray" ) }
.zj2qie{ background: map-get( $Color, "Light" ) }
/* 
    ----------
*/
.h7xf2d{ display: flex }
.op6w0g{ display: inline-flex }
.w71djm{ display: block }
.y3pad4{ display: inline-block }
.w9ksr0{ display: grid }
.a9f11l{ display: inline }
.c5fgoj{ display: table }
.x9yrop{ display: table-cell }
.px4w8t{ display: table-row }
.fpk0mq{ display: -webkit-box }
.qv8dnr{ display: none }
/* 
    ----------
*/
.g4g53f{ flex-direction: row }
.pbmv5l{ flex-direction: row-reverse }
.ims10u{ flex-direction: column }
.rc5ub6{ flex-direction: column-reverse }
/* 
    ----------
*/
.m012dh{ justify-content: flex-start }
.jltv8f{ justify-content: flex-end }
.kk7aan{ justify-content: center }
.t9opde{ justify-content: space-between }
.llv34z{ justify-content: space-around }
.v2rujd{ justify-content: space-evenly }
/* 
    ----------
*/
.xc8yuo{ align-items: flex-start }
.r03tfg{ align-items: flex-end }
.s158zk{ align-items: center }
.x7989y{ align-items: baseline }
.yklt75{ align-items: stretch }
/* 
    ----------
*/
.uxd187{ text-align: left }
.h2q2ji{ text-align: right }
.r8732b{ text-align: center }
.ictt32{ text-align: justify }
/* 
    ----------
*/
.d1g7wg{ text-transform: lowercase }
.wf8rk9{ text-transform: uppercase }
.w82xqt{ text-transform: capitalize }
/*
    ----------
*/
.jns0hf{ text-decoration: none }
.w6dz2a{ text-decoration: underline }
.ltm3s6{ text-decoration: line-through }
/*
    ----------
*/
.tsb1g0{ text-overflow: ellipsis }
.a1gq97{ text-overflow: unset }
/*
    ----------
*/
.t3hjzl{ vertical-align: baseline }
.oij59l{ vertical-align: top }
.s1ijwr{ vertical-align: middle }
.vbr70d{ vertical-align: sub }
.mh6a8d{ vertical-align: bottom }
.te6jqq{ vertical-align: text-top }
.k0qroa{ vertical-align: text-bottom }
/*
    ----------
*/
.c6g964{ white-space: nowrap }
.dvls4s{ white-space: pre }
/* 
    ----------
*/
.f3z1jd{ position: relative }
.k0ornk{ position: absolute }
.s610d9{ position: static }
.xs4ti7{ position: fixed }
.b2j85v{ position: sticky }
/*
    ----------
*/
.lyz4n5{ overflow: auto }
.tj4keb{ overflow: hidden }
.urku55{ overflow: visible }
.dft15o{ overflow: scroll }
.j2tbgs{ overflow-x: hidden }
.xq84t2{ overflow-y: scroll }
/*
    ----------
*/
.wd9i3m{ cursor: pointer }
.y3w4bx{ cursor: zoom-in }
.z266ep{ cursor: none }
/*
    ----------
*/
.or85j1{ pointer-events: none }
/*
    ----------
*/
.e4yqra{ border: 0 }
.ay10ih{ border-top: 0 }
.cj757j{ border-bottom: 0 }
.epi8mi{ border-left: 0 }
.d55oow{ border-right: 0 }
/*
    ----------
*/
.h0m3h5{ margin: 0 }
.nl527z{ margin-left: 0 }
.c3f4a0{ margin-right: 0 }
.k7fjtt{ margin-top: 0 }
.oqj9oi{ margin-bottom: 0 }
/*
    ----------
*/
.d78409{ margin: 0 auto }
/*
    ----------
*/
.rtfe20{ padding: 0 }
.ph8h4i{ padding-left: 0 }
.hg09ga{ padding-right: 0 }
.vi2x2o{ padding-top: 0 }
.n5jmt4{ padding-bottom: 0 }
/*
    ----------
*/
.xgh8qt{ border-radius: 0 }
.otf96k{ border-radius: 25% }
.uxk82z{ border-radius: 50% }
.vx8lza{ border-radius: 75% }
.t11c49{ border-radius: 100% }
/*
    ----------
*/
.z4h35m{ flex: 0 0 auto }
.q7a26v{ flex: 1 1 auto }
.b1duit{ flex: 0 1 auto }
.am57me{ flex: 1 0 auto }
/*
    ----------
*/
.br203t{ flex-grow: 0 }
.lb847t{ flex-grow: 1 }
.y4ig3v{ flex-grow: 2 }
.dt36bv{ flex-grow: 3 }
/*
    ----------
*/
.y1d927{ flex-basis: 1 }
.sq80z4{ flex-basis: 2 }
.h8l96k{ flex-basis: 3 }
/*
    ----------
*/
.zu7vp4{ flex-wrap: wrap }
.j9alyr{ flex-wrap: nowrap }
.l0owzi{ flex-wrap: wrap-reverse }
/*
    ----------
*/
.zt3vpa{ order: 1 }
.komd1n{ order: 2 }
.l9c0yc{ order: 3 }
/*
    ----------
*/
.j6om92{ align-content: flex-start }
.ht1r4j{ align-content: flex-end }
.g0b1v1{ align-content: center }
.y9qg4r{ align-content: space-between }
.q2fgh3{ align-content: space-around }
/*
    ----------
*/
.jrrv9d{ flex-shrink: 0 }
/*
    ----------
*/
.a7s61g{ box-shadow: none }
/*
    ----------
*/
.fap9z1{ content: "" }
/* 
    ----------
*/
.tfm696{ outline: 0 }
/* 
    ----------
*/
.rn36iv{ opacity: 1 }
.vz2kol{ opacity: 0 }
.jfb9rb{ opacity: 50% }
.s62k52{ opacity: 75% }
.iipl7r{ opacity: 100% }
/*
    ----------
*/
.e6pr5u{ -webkit-line-clamp: 2 }
.hka0sy{ -webkit-line-clamp: 3 }
.op901g{ -webkit-line-clamp: 4 }
/*
    ----------
*/
.hc4k4o{ -webkit-box-orient: vertical }
/*
    ----------
*/
.z4sfok{ list-style: none }
.u640hh{ list-style: square }
.wt30x2{ list-style: circle }
/*
    ----------
*/
.pf5i85{ writing-mode: vertical-lr }
.m41p82{ writing-mode: horizontal-tb }
/*
    ----------
*/
.kf1va4{ transition: all .8s ease }
/*
    ----------
*/
.p9wjuz{ transform: rotate(90deg) }
.hvj5o0{ transform: rotate(180deg) }
.w3375p{ transform: rotate(-180deg) }
.a4jo34{ transform: rotate(270deg) }
.h8f99o{ transform: rotate(360deg) }
/*
    ----------
*/
.d0aefj{ object-fit: cover }
/*
    ----------
*/
.x5p0r5{ background-position: right center }
.lz81kc{ background-position: top center }
.pf7u3c{ background-position: bottom center }
.av7hs4{ background-position: center center }
/*
    ----------
*/
.dwt2t0{ background-size: cover }
/*
    ----------
*/
.i3saxv{ background-repeat: no-repeat }
/*
    ----------
*/
.atem6a{ clip-path: polygon( 0% 0%, 100% 100%, 0% 100%)  }
/*
    ----------
*/
.r17j04{ filter: grayscale(50%) }
.n8969l{ filter: saturate(3) }
.p7wa4b{ filter: contrast(160%) }
.p0b4n5{ filter: brightness(0.25) }
.hi3gcq{ filter: blur(3px) }
.ihp7ra{ filter: invert(100%) }
.fv8sz5{ filter: sepia(100%) }
.a60drj{ filter: hue-rotate(180deg) }
/* 
    ----------
*/
.h7xf2d{ display: flex }
.op6w0g{ display: inline-flex }
.w71djm{ display: block }
.y3pad4{ display: inline-block }
.w9ksr0{ display: grid }
.a9f11l{ display: inline }
.c5fgoj{ display: table }
.x9yrop{ display: table-cell }
.px4w8t{ display: table-row }
.fpk0mq{ display: -webkit-box }
.qv8dnr{ display: none }
/* 
    ----------
*/
.g4g53f{ flex-direction: row }
.pbmv5l{ flex-direction: row-reverse }
.ims10u{ flex-direction: column }
.rc5ub6{ flex-direction: column-reverse }
/* 
    ----------
*/
.m012dh{ justify-content: flex-start }
.jltv8f{ justify-content: flex-end }
.kk7aan{ justify-content: center }
.t9opde{ justify-content: space-between }
.llv34z{ justify-content: space-around }
.v2rujd{ justify-content: space-evenly }
/* 
    ----------
*/
.xc8yuo{ align-items: flex-start }
.r03tfg{ align-items: flex-end }
.s158zk{ align-items: center }
.x7989y{ align-items: baseline }
.yklt75{ align-items: stretch }
/* 
    ----------
*/
.uxd187{ text-align: left }
.h2q2ji{ text-align: right }
.r8732b{ text-align: center }
.ictt32{ text-align: justify }
/* 
    ----------
*/
.d1g7wg{ text-transform: lowercase }
.wf8rk9{ text-transform: uppercase }
.w82xqt{ text-transform: capitalize }
/*
    ----------
*/
.jns0hf{ text-decoration: none }
.w6dz2a{ text-decoration: underline }
.ltm3s6{ text-decoration: line-through }
/*
    ----------
*/
.tsb1g0{ text-overflow: ellipsis }
.a1gq97{ text-overflow: unset }
/*
    ----------
*/
.t3hjzl{ vertical-align: baseline }
.oij59l{ vertical-align: top }
.s1ijwr{ vertical-align: middle }
.vbr70d{ vertical-align: sub }
.mh6a8d{ vertical-align: bottom }
.te6jqq{ vertical-align: text-top }
.k0qroa{ vertical-align: text-bottom }
/*
    ----------
*/
.c6g964{ white-space: nowrap }
.dvls4s{ white-space: pre }
/* 
    ----------
*/
.f3z1jd{ position: relative }
.k0ornk{ position: absolute }
.s610d9{ position: static }
.xs4ti7{ position: fixed }
.b2j85v{ position: sticky }
/*
    ----------
*/
.lyz4n5{ overflow: auto }
.tj4keb{ overflow: hidden }
.urku55{ overflow: visible }
.dft15o{ overflow: scroll }
.j2tbgs{ overflow-x: hidden }
.xq84t2{ overflow-y: scroll }
/*
    ----------
*/
.wd9i3m{ cursor: pointer }
.y3w4bx{ cursor: zoom-in }
/*
    ----------
*/
.or85j1{ pointer-events: none }
/*
    ----------
*/
.e4yqra{ border: 0 }
.ay10ih{ border-top: 0 }
.cj757j{ border-bottom: 0 }
.epi8mi{ border-left: 0 }
.d55oow{ border-right: 0 }
/*
    ----------
*/
.h0m3h5{ margin: 0 }
.nl527z{ margin-left: 0 }
.c3f4a0{ margin-right: 0 }
.k7fjtt{ margin-top: 0 }
.oqj9oi{ margin-bottom: 0 }
/*
    ----------
*/
.d78409{ margin: 0 auto }
/*
    ----------
*/
.rtfe20{ padding: 0 }
.ph8h4i{ padding-left: 0 }
.hg09ga{ padding-right: 0 }
.vi2x2o{ padding-top: 0 }
.n5jmt4{ padding-bottom: 0 }
/*
    ----------
*/
.xgh8qt{ border-radius: 0 }
.otf96k{ border-radius: 25% }
.uxk82z{ border-radius: 50% }
.vx8lza{ border-radius: 75% }
.t11c49{ border-radius: 100% }
/*
    ----------
*/
.z4h35m{ flex: 0 0 auto }
.q7a26v{ flex: 1 1 auto }
.b1duit{ flex: 0 1 auto }
.am57me{ flex: 1 0 auto }
/*
    ----------
*/
.br203t{ flex-grow: 0 }
.lb847t{ flex-grow: 1 }
.y4ig3v{ flex-grow: 2 }
.dt36bv{ flex-grow: 3 }
/*
    ----------
*/
.y1d927{ flex-basis: 1 }
.sq80z4{ flex-basis: 2 }
.h8l96k{ flex-basis: 3 }
/*
    ----------
*/
.zu7vp4{ flex-wrap: wrap }
.j9alyr{ flex-wrap: nowrap }
.l0owzi{ flex-wrap: wrap-reverse }
/*
    ----------
*/
.zt3vpa{ order: 1 }
.komd1n{ order: 2 }
.l9c0yc{ order: 3 }
/*
    ----------
*/
.j6om92{ align-content: flex-start }
.ht1r4j{ align-content: flex-end }
.g0b1v1{ align-content: center }
.y9qg4r{ align-content: space-between }
.q2fgh3{ align-content: space-around }
/*
    ----------
*/
.jrrv9d{ flex-shrink: 0 }
/*
    ----------
*/
.a7s61g{ box-shadow: none }
/*
    ----------
*/
.fap9z1{ content: "" }
/* 
    ----------
*/
.tfm696{ outline: 0 }
/* 
    ----------
*/
.rn36iv{ opacity: 1 }
.vz2kol{ opacity: 0 }
.jfb9rb{ opacity: 50% }
.s62k52{ opacity: 75% }
.iipl7r{ opacity: 100% }
/*
    ----------
*/
.e6pr5u{ -webkit-line-clamp: 2 }
.hka0sy{ -webkit-line-clamp: 3 }
.op901g{ -webkit-line-clamp: 4 }
/*
    ----------
*/
.hc4k4o{ -webkit-box-orient: vertical }
/*
    ----------
*/
.z4sfok{ list-style: none }
.u640hh{ list-style: square }
.wt30x2{ list-style: circle }
/*
    ----------
*/
.pf5i85{ writing-mode: vertical-lr }
.m41p82{ writing-mode: horizontal-tb }
/*
    ----------
*/
.kf1va4{ transition: all .8s ease }
/*
    ----------
*/
.p9wjuz{ transform: rotate(90deg) }
.hvj5o0{ transform: rotate(180deg) }
.w3375p{ transform: rotate(-180deg) }
.a4jo34{ transform: rotate(270deg) }
.h8f99o{ transform: rotate(360deg) }
/*
    ----------
*/
.d0aefj{ object-fit: cover }
/*
    ----------
*/
.x5p0r5{ background-position: right center }
.lz81kc{ background-position: top center }
.pf7u3c{ background-position: bottom center }
.av7hs4{ background-position: center center }
/*
    ----------
*/
.dwt2t0{ background-size: cover }
/*
    ----------
*/
.i3saxv{ background-repeat: no-repeat }
/*
    ----------
*/
.atem6a{ clip-path: polygon( 0% 0%, 100% 100%, 0% 100%)  }
/*
    ----------
*/
.r17j04{ filter: grayscale(50%) }
.n8969l{ filter: saturate(3) }
.p7wa4b{ filter: contrast(160%) }
.p0b4n5{ filter: brightness(0.25) }
.hi3gcq{ filter: blur(3px) }
.ihp7ra{ filter: invert(100%) }
.fv8sz5{ filter: sepia(100%) }
.a60drj{ filter: hue-rotate(180deg) }
/*
    ----------
*/
@mixin Web-Size( $Width, $Height ){ width: $Width; height: $Height }
@mixin Web-Top-Start( $Start, $Top ){ left: $Start; top: $Top }
@mixin Web-Top-End( $End, $Top ){ right: $End; top: $Top }
@mixin Web-Bottom-Start( $Start, $Bottom ){ left: $Start; bottom: $Bottom }
@mixin Web-Bottom-End( $End, $Bottom ){ right: $End; bottom: $Bottom }
/*
    ----------
*/
@font-face {
    font-family: "GT Walsheim";
    src: url( "./Media/Font/GT-Walsheim-Regular.woff2" ) format( 'woff2' ),
        url( "./Media/Font/GT-Walsheim-Regular.woff" ) format( 'woff' );
    font-weight: 400;
}
@font-face {
    font-family: "GT Walsheim";
    src: url( "./Media/Font/GT-Walsheim-Medium.woff2" ) format( 'woff2' ),
        url( "./Media/Font/GT-Walsheim-Regular.woff" ) format( 'woff' );
    font-weight: 500;
}
@font-face {
    font-family: "GT Walsheim";
    src: url( "./Media/Font/GT-Walsheim-Bold.woff2" ) format( 'woff2' ),
         url( "./Media/Font/GT-Walsheim-Bold.woff" ) format( 'woff' );
    font-weight: 700;
}
/* Root */
:root{
    --Web-DS17R4 : Unbounded;
}
:root{
    --Web-A6R9D4 : 12.5px;
    --Web-B0JI04 : 13.5px;
    --Web-N471BU : 14.5px;
    --Web-P8H136 : 15.5px;
    --Web-O998SP : 16.5px;
    --Web-TL9855 : 17.5px;
    --Web-R40061 : 18.5px;
    --Web-A7YI66 : 19.5px;
    --Web-H79151 : 20.5px;
    --Web-KEK904 : 21px;
    --Web-I4E344 : 22px;
    --Web-W4Z44C : 23px;
    --Web-H018QA : 24px;
}
:root{
    --Web-R12YYN : #C3002F;
    --Web-BKD1HE : #FFFFFF;
    --Web-P82352 : #F1F1F1;
    --Web-QE683D : #000000;
    --Web-S6140O : #1D1D1B;
    --Web-KVD034 : #222D35;
    --Web-M7S21M : #C8C8C8;
    --Web-O9FU61 : #CECECE;
    --Web-D6X5A4 : #8A9198;
}
/* Reset  */  
a, a:hover{
    @extend .jns0hf;
}
input, input:focus{ 
    @extend .tfm696;
    @extend .xgh8qt;
}
/* Body */
body{
    @extend .yc0r22;
    font-family: map-get( $Font-Family, "Default" );
    font-size: map-get( $Font-Size, "Fiveteen" );
    &::-webkit-scrollbar{
        @include Web-Size( 5px, auto );
    }
    &::-webkit-scrollbar-track{
        @extend .zj2qie;
    }
    &::-webkit-scrollbar-thumb{
        @extend .z82y3r;
    }
}
/* Header */
.x3287n{
    @include Web-Size( 100%, 45px );
    @extend .zj2qie;
    .l7zj25{
        @extend .vwei3j;
        @extend .r8q4zo;
        @extend .hx2bzw;
        line-height: 45px
    }
    .z35u1h{
        @include Web-Size( 100%, 45px );
        .s9wh57{
            @include Web-Size( 21px, 21px );
            @extend .kf1va4;
            @extend .tfm696;
            @extend .os7ol5;
            @extend .e4yqra;
            svg{
                @include Web-Size( 13.5px, 13.5px );
                g{
                    @extend .ctj6s0;
                }
            }
            &:hover{
                @extend .z82y3r;
                @extend .e4yqra;
                svg{
                    g{
                        @extend .mo9q0r;
                    }
                }
            }
        }
    }
}
.g67d7f{
    @include Web-Size( 100%, 55px );
    @extend .os7ol5;
    @extend .f3z1jd;
    &::after{
        @include Web-Top-End( 0, 0 );
        @include Web-Size( 30%, 100% );
        @extend .fap9z1;
        @extend .k0ornk;
        @extend .z82y3r;
        z-index: 99;
    }
    .f6n64y{
        @include Web-Size( 100%, 55px );
        @extend .kf1va4;
        @extend .tfm696;
        .m96l9m{
            @extend .ju7ot2;
            @extend .vbv58q;
            font-family: var(--Web-DS17R4);
            letter-spacing: .5px;
            &::after{
                color: var(--Web-R12YYN);
                content: ".com";
            }
        }
    }
    .f61770{
        @include Web-Size( 100%, 60px );
        ul{
            @extend .z4sfok;
            li{
                a{
                    @extend .flhu41;
                    @extend .ju7ot2;
                    @extend .vwei3j;
                    @extend .h0m3h5;
                    @extend .f3z1jd;
                    padding: 0 16px;
                    line-height: 55px;
                    &:hover{
                        animation: Menu .1s linear;
                        animation-fill-mode: forwards;
                    }
                }
                ul{
                    @include Web-Top-Start( 0, 55px );
                    @extend .z4sfok;
                    @extend .o3u62u;
                    @extend .qv8dnr;
                    min-width: 250px;
                    z-index: 999;
                    li{
                        padding: 0 20px;
                        a{
                            @include Web-Size( 100%, 44px );
                            @extend .px0inf;
                            @extend .z4ctyy;
                            @extend .h0m3h5;
                            @extend .rtfe20;
                            @extend .kf1va4;
                            font-size: var(--Web-N471BU);
                            border-bottom: 1px solid rgba( map-get( $Color, "White" ), .05 );
                            animation: none!important;
                            line-height: 44px;
                            .qx3736{
                                svg{
                                    @include Web-Size( 14.5px, 14.5px );
                                    margin: .5px 0 0 0;
                                    path{
                                        @extend .auu562;
                                        @extend .kf1va4;
                                    }
                                }
                            }
                            &:hover{
                                @extend .od5yo1;
                                .ih2l14{
                                    &:hover{
                                        animation: Slide .1s linear;
                                        animation-fill-mode: forwards;
                                    }
                                }
                                .qx3736{
                                    svg{
                                        path{
                                            @extend .mo9q0r;
                                        }
                                    }
                                }
                            }
                        }
                        &:last-child a{
                            @extend .cj757j;
                        }
                    }
                    ul{
                        @include Web-Top-Start( 250px, 0 );
                        @extend .qv8dnr;
                        min-width: 300px;
                        li{
                            a{
                                &:hover{
                                    animation: Slide .2s linear!important;
                                    animation-fill-mode: forwards!important;
                                }
                            }
                        }
                    }
                }
                &:first-child{
                    a{
                        @extend .ph8h4i;
                    }
                }
                &:last-child{
                    a{
                        @extend .hg09ga;
                    }
                }
                &:hover > ul{
                    @extend .w71djm;
                }
            }
        }
    }
    .e775b0{
        z-index: 9999;
        .m6i04i{
            @include Web-Size( 100%, 55px );
            z-index: 9999;
            .i123i7{
                @extend .od5yo1;
                @extend .ju7ot2;
                @extend .djq82r;
                z-index: 9999;
            }
            .k27wk6{
                z-index: 9999;
                svg{
                    @include Web-Size( 19px, 19px );
                    margin: -2px 0 0 0;
                    g{
                        path{
                            @extend .mo9q0r;
                        }
                    }
                }
            }
        }
    }
}
.x80247{
    @extend .qv8dnr;
    .vy032c{
        .d3c272{
            @include Web-Size( 100%, 55px );
            @extend .z82y3r;
            .do615p{
                .u8018v{
                    @include Web-Size( 100%, 55px );
                    @extend .kf1va4;
                    @extend .tfm696;
                    svg{
                        @include Web-Size( 25px, 25px );
                    }
                }
                .i52ud3{
                    @include Web-Size( 100%, 55px );
                    @extend .kf1va4;
                    @extend .tfm696;
                    svg{
                        @include Web-Size( 25px, 25px );
                        margin: -1px 0 0 0;
                        g{
                            @extend .mo9q0r;
                        }
                    }
                }
                .b03th6{
                    @extend .vbv58q;
                    @extend .ju7ot2;
                    font-family: var(--Web-DS17R4);
                    line-height: 55px
                }
                .a0d503{
                    @include Web-Size( 100%, 55px );
                    @extend .kf1va4;
                    @extend .tfm696;
                    svg{
                        @include Web-Size( 21px, 21px );
                        path{
                            @extend .mo9q0r;
                        }
                    }
                }
            }
        }
        .n7j1g8{
            @include Web-Size( 100%, 45px );
            @extend .zj2qie;
            .oq9210{
                @extend .tfm696;
                @extend .kf1va4;
                .h50iu1{
                    @include Web-Size( 90%, auto );
                    @extend .flhu41;
                    @extend .vwei3j;
                    line-height: 45px;
                }
                .tz9913{
                    @include Web-Size( 10%, 45px );
                    @extend .tfm696;
                    @extend .kf1va4;
                    svg{
                        @include Web-Size( 19px, 19px );
                        g{
                            @extend .lk1jon;
                        }
                    }
                }
            }
        }
    }
    .k729de{
        @include Web-Top-Start( 0, 100px );
        @include Web-Size( 100%, 100vh );
        @extend .o3u62u;
        overflow-y: scroll;
        z-index: 9999;
        .qm453k{
            @include Web-Size( 100%, 60px );
            @extend .tfm696;
            @extend .kf1va4;
            border-bottom: 1px solid rgba( map-get( $Color, "White" ), .05 );
            .r929rj{
                @include Web-Size( 32px, 32px );
                border: 1px solid rgba( map-get( $Color, "White" ), .05 );
                svg{
                    @include Web-Size( 18px, 18px );
                    margin: 0;
                }
            }
            .m0qi92{
                @extend .flhu41;
                @extend .ju7ot2;
                @extend .px0inf;
                line-height: 60px
            }
            .qv695v{
                @extend .djq82r;
                @extend .ju7ot2;
                @extend .px0inf;
                line-height: 60px
            }
        }
        .s0081p{
            @include Web-Size( 100%, calc( 100vh + 45px ) );
            @extend .z4sfok;
            li{
                a{
                    @include Web-Size( 100%, 55px );
                    border-bottom: 1px solid rgba( map-get( $Color, "White" ), .05 );
                    .k85s3n{
                        @extend .flhu41;
                        @extend .px0inf;
                        @extend .ju7ot2;
                        line-height: 55px
                    }
                    .v814mt{
                        @extend .tfm696;
                        @extend .kf1va4;
                        svg{
                            @include Web-Size( 16px, 16px );
                            margin: -1px 0 0 0;
                            path{
                                @extend .auu562;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Main */
.p2628r{
    .v986gy{
        @extend .f3z1jd;
        .h1r483{
            @include Web-Size( 100%, 636px );
            @extend .dwt2t0;
            @extend .i3saxv;
            @extend .av7hs4;
            @extend .zj2qie;
            background-image: url( "./Media/Image/013.jpg" );
        }
    }
    .t90i22{
        .x09f37{
            @extend .hx2bzw;
            @extend .jjp8x6;
            font-size: var(--Web-KEK904);
            max-width: 520px;
        }
        .e9l174{
            @extend .hx2bzw;
            @extend .jjp8x6;
            @extend .qv8dnr;
            @extend .ny62tq;
        }
        .t2f180{
            @extend .vwei3j;
            @extend .flhu41;
            max-width: 450px;
            line-height: 27px
        }
        .a82g3r{
            @extend .vwei3j;
            @extend .flhu41;
            @extend .qv8dnr;
            line-height: 27px
        }
        .e80c17{
            @include Web-Size( 175px, 42px );
            @extend .z82y3r;
            @extend .kf1va4;
            border-radius: 42px;
            .y1928i{
                @extend .djq82r;
                @extend .od5yo1;
            }
            &:hover{
                @extend .o3u62u;
            }
        }
    }
}
.p53us8{
    @extend .f3z1jd;
    .y3vo86{
        @extend .flhu41;
        @extend .hx2bzw;
        @extend .ju7ot2;
        line-height: 75px;
    }
    .ns467g{
        @extend .flhu41;
        @extend .od5yo1;
        @extend .ju7ot2;
        @extend .f3z1jd;
        line-height: 75px;
        z-index: 89;
    }
    .m0p16l{
        @extend .yc0r22;
        @extend .ju7ot2;
        font-size: var(--Web-P8H136);
        line-height: 75px;
    }
}
.s0b6y6{
    .y8017f{
        box-shadow: 65px 0 0 -40px map-get( $Color, "Default" );
        .b62125{
            @include Web-Size( 100%, 636px );
            @extend .zj2qie;
            @extend .i3saxv;
            @extend .dwt2t0;
            @extend .av7hs4;
            background-image: url( "./Media/Image/007.jpg" );
        }
    }
    .we012g{
        @include Web-Size( 100%, 636px );
        .y6rh29{
            @extend .hx2bzw;
            @extend .jjp8x6;
            font-size: var(--Web-H79151);
            line-height: 29px
        }
        .n2017b{
            @extend .vwei3j;
            @extend .flhu41;
            max-width: 410px;
            line-height: 29px
        }
        .u97139{
            @extend .e4yqra;
            padding: 3px 0 6px 0;
            border-bottom: 1px solid map-get( $Color, "Default" );
            .nt098c{
                @extend .vwei3j;
                @extend .ju7ot2;
                font-size: var(--Web-P8H136);
                line-height: 20px
            }
            .a81e4p{
                @extend .kf1va4;
                svg{
                    @include Web-Size( 14px, 14px );
                    @extend .ke7ifl;
                    margin: -2px 0 0 0;
                    rect{
                        @extend .ke7ifl;
                    }
                    path{
                        @extend .ke7ifl;
                    }
                }
            }
            &:hover{
                .a81e4p{
                    animation: Slide .2s linear;
                    animation-fill-mode: forwards
                }
            }
        }
    }
}
.z752vl{
    .h2314u{
        @extend .tfm696;
        @extend .kf1va4;
        .zk98z9{
            @include Web-Size( 48px, 48px );
            @extend .zj2qie;
        }
        .a6ox73{
            @include Web-Top-Start( 0, 0 );
            padding: 0 0 0 65px;
            .c8g6r0{
                @extend .flhu41;
                @extend .hx2bzw;
                line-height: 48px;
            }
        }
    }
}
.c0046v{
    @include Web-Size( 100%, 455px );
    @extend .f3z1jd;
    @extend .z82y3r;
    .n1945q{
        @include Web-Size( 100%, 455px );
        .q26962{
            @extend .od5yo1;
            @extend .jjp8x6;
            font-size: var(--Web-KEK904);
        }
        .d8t671{
            @extend .od5yo1;
            @extend .jjp8x6;
            font-size: var(--Web-KEK904);
            line-height: 33px;
        }
        .r66d51{
            @extend .od5yo1;
            @extend .z4ctyy;
            font-size: var(--Web-O998SP);
            line-height: 23px;
        }
    }
    &::before{
        @include Web-Top-Start( 0, 0 );
        @include Web-Size( 100%, 48px );
        @extend .fap9z1;
        @extend .k0ornk;
        @extend .os7ol5;
    }
}
.m444ho{
    .k3039j{
        @include Web-Size( 100%, 318px );
        @extend .zj2qie;
    }
    .p594hm{
        z-index: 1;
        .n9v17b{
            @extend .vbv58q;
            @extend .hx2bzw;
            @extend .jjp8x6;
        }
        .hx030l{
            @extend .flhu41;
            @extend .yc0r22;
            @extend .ju7ot2;
            line-height: 22px
        }
        .w9z2e0{
            @extend .flhu41;
            @extend .vwei3j;
            @extend .l9kha6;
            @extend .hc4k4o;
            @extend .fpk0mq;
            @extend .e6pr5u;
            line-height: 25px
        }
    }
}
.y5x1g0{
    @include Web-Size( 100%, 25px );
    @extend .os7ol5;
    .x211t5{
        @extend .wd9i3m;
        @extend .tfm696; 
        svg{
            @include Web-Size( 19px, 19px );
            rect{
                @extend .ke7ifl;
            }
            path{
                @extend .ke7ifl;
            }
        }
    }
}
.dxq251{
    .q6510h{
        .k43h8o{
            @extend .hx2bzw;
            @extend .jjp8x6;
            font-size: var(--Web-H79151);
        }
        .e2lm99{
            @extend .flhu41;
            @extend .n25600;
            @extend .l9kha6;
            line-height: 26px
        }
    }
}
.ogi422{
    .ub6472{
        @include Web-Size( 100%, 208px );
        @extend .zj2qie;
        img{
            @include Web-Size( 100%, auto );
        }
    }
    .ss1j85{
        @extend .hx2bzw;
        @extend .ju7ot2;
        @extend .uu8xpu;
        line-height: 25px
    }
}
.p1z96d{
    .te668i{
        @include Web-Size( 100%, 502px );
        @extend .zj2qie;
        iframe{
            @include Web-Size( 100%, 100% );
        }
    }
    .nxl288{
        background: linear-gradient( 90deg, map-get( $Color, "Light" ) 100%, map-get( $Color, "White" ) 0% );
        .o6885d{
            .s3704x{
                @include Web-Size( 100%, 40px );
                @extend .os7ol5;
                @extend .hx2bzw;
                @extend .l9kha6;
                @extend .tfm696;
                @extend .e4yqra;
                border: 1px solid var(--Web-M7S21M);
                font-size: var(--Web-N471BU);
                border-radius: 4px;
                &::placeholder{
                    @extend .hx2bzw;
                    font-size: var(--Web-N471BU);
                }
            }
            .af8k72{
                @include Web-Size( 100%, 90px );
                @extend .os7ol5;
                @extend .hx2bzw;
                @extend .l9kha6;
                @extend .tfm696;
                @extend .e4yqra;
                border: 1px solid var(--Web-M7S21M);
                font-size: var(--Web-N471BU);
                border-radius: 4px;
                &::placeholder{
                    @extend .hx2bzw;
                    font-size: var(--Web-N471BU);
                }
            }
            .h84059{
                @include Web-Size( 100%, 44px );
                @extend .o3u62u;
                @extend .tfm696;
                @extend .kf1va4;
                @extend .od5yo1;
                @extend .djq82r;
                border-radius: 4px;
                line-height: 44px;
                &:hover{
                    @extend .z82y3r;
                }
            }
        }
        .s624xi{
            @extend .ju7ot2;
            @extend .ny62tq;
            @extend .vwei3j;
        }
        .r3u8c9{
            @extend .z4ctyy;
            @extend .vwei3j;
            font-size: var(--Web-P8H136);
            line-height: 10px
        }
        .h3t02u{
            font-size: var(--Web-P8H136);
            color: var(--Web-S6140O);
        }
    }
}
.n70rm0{
    .z18h33{
        @include Web-Size( 100%, 180px );
        .teo750{
            @extend .vwei3j;
            @extend .ju7ot2;
            font-size: var(--Web-R40061);
            line-height: 23px
        }
        .zn03m4{
            @extend .yc0r22;
            @extend .ju7ot2;
            font-size: var(--Web-R40061);
        }
    }
}
.ja557z{
    .n454f7{
        @include Web-Size( 100%, auto );
        .s33610{
            @include Web-Size( 110px, 110px );
        }
        .xb880z{
            .u763kh{
                @extend .vwei3j;
                @extend .z4ctyy;
                @extend .flhu41;
                line-height: 35px
            }
        }
    }
}
.w531jt{
    @extend .f3z1jd;
    .e22q94{
        @include Web-Size( 100%, 115px );
        @extend .i3saxv;
        @extend .dwt2t0;
        @extend .lz81kc;
        @extend .z82y3r;
        .p750g9{
            @extend .od5yo1;
            @extend .jjp8x6;
            font-size: var(--Web-W4Z44C);
        }
    }
}
.f1j45n{
    @include Web-Size( 100%, 50px );
    .z272kl{
        @include Web-Size( 100%, 50px );
        @extend .z4sfok;
        li{
            a{
                @extend .flhu41;
                @extend .vwei3j;
                &:hover span{
                    @extend .w6dz2a;
                }
            }
        }
    }
    .zoq741{
        @extend .kf1va4;
        svg{
            @include Web-Size( 15px, 15px );
            margin: -1px 0 0 0;
            path{
                @extend .lk1jon;
            }
        }
    }
}
.o6l4h3{
    @include Web-Size( 100%, 100% );
    .t37yn4{
        .v5x9d3{
            @extend .kf1va4;
            @extend .tfm696;
            .mo943y{
                .gx003r{
                    @extend .b6zmr2;
                    font-size: var(--Web-KEK904);
                }
                .d233cz{
                    @extend .flhu41;
                    line-height: 25px
                }
            }
            .r5ta95{
                @include Web-Size( 100%, 127px );
                @extend .dwt2t0;
                @extend .i3saxv;
                @extend .av7hs4;
                background-image: url( "./Media/Image/014.jpg" );
                &::after{
                    @include Web-Size( 100%, 100% );
                    @include Web-Top-Start( 0, 0 );
                    @extend .fap9z1;
                    @extend .k0ornk;
                    background: rgba( map-get( $Color, "Black" ), .4 );
                }
            }
            .h9514u{
                @include Web-Size( 100%, 127px );
                @extend .dwt2t0;
                @extend .i3saxv;
                @extend .av7hs4;
                background-image: url( "./Media/Image/015.jpg" );
                &::after{
                    @include Web-Size( 100%, 100% );
                    @include Web-Top-Start( 0, 0 );
                    @extend .fap9z1;
                    @extend .k0ornk;
                    background: rgba( map-get( $Color, "Black" ), .4 );
                }
            }
            .o3164p{
                @include Web-Size( 100%, 127px );
                @extend .dwt2t0;
                @extend .i3saxv;
                @extend .av7hs4;
                background-image: url( "./Media/Image/016.jpg" );
                &::after{
                    @include Web-Size( 100%, 100% );
                    @include Web-Top-Start( 0, 0 );
                    @extend .fap9z1;
                    @extend .k0ornk;
                    background: rgba( map-get( $Color, "Black" ), .4 );
                }
            }
            .q7j578{
                @include Web-Size( 100%, 127px );
                @extend .dwt2t0;
                @extend .i3saxv;
                @extend .av7hs4;
                background-image: url( "./Media/Image/017.jpg" );
                &::after{
                    @include Web-Size( 100%, 100% );
                    @include Web-Top-Start( 0, 0 );
                    @extend .fap9z1;
                    @extend .k0ornk;
                    background: rgba( map-get( $Color, "Black" ), .4 );
                }
            }
            .p3i5n5{
                @include Web-Size( 100%, 127px );
                @extend .dwt2t0;
                @extend .i3saxv;
                @extend .av7hs4;
                background-image: url( "./Media/Image/018.jpg" );
                &::after{
                    @include Web-Size( 100%, 100% );
                    @include Web-Top-Start( 0, 0 );
                    @extend .fap9z1;
                    @extend .k0ornk;
                    background: rgba( map-get( $Color, "Black" ), .4 );
                }
            }
            .m65434{
                @include Web-Size( 100%, 127px );
                @extend .dwt2t0;
                @extend .i3saxv;
                @extend .av7hs4;
                background-image: url( "./Media/Image/019.jpg" );
                &::after{
                    @include Web-Size( 100%, 100% );
                    @include Web-Top-Start( 0, 0 );
                    @extend .fap9z1;
                    @extend .k0ornk;
                    background: rgba( map-get( $Color, "Black" ), .4 );
                }
            }
            .z19w39{
                @include Web-Size( 100%, 127px );
                @extend .dwt2t0;
                @extend .i3saxv;
                @extend .av7hs4;
                background-image: url( "./Media/Image/020.jpg" );
                &::after{
                    @include Web-Size( 100%, 100% );
                    @include Web-Top-Start( 0, 0 );
                    @extend .fap9z1;
                    @extend .k0ornk;
                    background: rgba( map-get( $Color, "Black" ), .4 );
                }
            }
            .oap905{
                @include Web-Size( 100%, 127px );
                @extend .dwt2t0;
                @extend .i3saxv;
                @extend .av7hs4;
                background-image: url( "./Media/Image/021.jpg" );
                &::after{
                    @include Web-Size( 100%, 100% );
                    @include Web-Top-Start( 0, 0 );
                    @extend .fap9z1;
                    @extend .k0ornk;
                    background: rgba( map-get( $Color, "Black" ), .4 );
                }
            }
            .i501tu{
                @include Web-Size( 100%, 127px );
                @extend .dwt2t0;
                @extend .i3saxv;
                @extend .av7hs4;
                background-image: url( "./Media/Image/022.jpg" );
                &::after{
                    @include Web-Size( 100%, 100% );
                    @include Web-Top-Start( 0, 0 );
                    @extend .fap9z1;
                    @extend .k0ornk;
                    background: rgba( map-get( $Color, "Black" ), .4 );
                }
            }
        }
    }
    .q98p51{
        @include Web-Size( 100%, 636px );
        @extend .dwt2t0;
        @extend .i3saxv;
        @extend .av7hs4;
        background-image: url( "./Media/Image/013.jpg" );
    }
    .zi8021{
        .d593vy{
            @extend .kf1va4;
            @extend .tfm696;
            .eb068o{
                @include Web-Size( 100%, 50px );
                @extend .os7ol5;
                border: 1px solid map-get( $Color, "Light" );
                .pg153m{
                    @include Web-Size( 5%, auto );
                    line-height: 50px
                }
                .go895o{
                    @include Web-Size( 90%, auto );
                    @extend .flhu41;
                    line-height: 49px
                }
                .zv2t48{
                    @include Web-Size( 5%, 50px );
                    line-height: 50px;
                    svg{
                        @include Web-Size( 16px, 16px );
                        path{
                            @extend .ctj6s0;
                        }
                    }
                }
            }
            .h0w94i{
                @include Web-Size( 100%, auto );
                border: 1px solid map-get( $Color, "Light" );
                .n4yo21{
                    @extend .flhu41;
                    @extend .vwei3j;
                    line-height: 28px
                }
            }
        }
    }
    .mq873u{
        .w780q7{
            @extend .kf1va4;
            @extend .tfm696;
            .t6315y{
                @include Web-Size( 100%, 55px );
                @extend .os7ol5;
                border: 1px solid var(--Web-M7S21M);
                .wi264q{
                    @include Web-Size( 90%, auto );
                    @extend .uu8xpu;
                    @extend .vwei3j;
                    line-height: 55px
                }
                .r64em2{
                    @include Web-Size( 10%, 50px );
                    line-height: 50px;
                    svg{
                        @include Web-Size( 18px, 18px );
                        path{
                            @extend .ctj6s0;
                        }
                    }
                }
            }
        }
    }
    .i02f1u{
        @include Web-Size( 100%, auto );
        .iy941h{
            @extend .hx2bzw;
            @extend .jjp8x6;
            @extend .vbv58q;
        }
        .oi4351{
            @extend .flhu41;
            @extend .vwei3j;
            @extend .ju7ot2;
            @extend .ictt32;
            line-height: 28px
        }
    }
    .q451ef{
        @include Web-Size( 100%, 636px );
        .dlh062{
            @extend .hx2bzw;
            @extend .jjp8x6;
            @extend .ny62tq;
        }
        .t3957w{
            @extend .uu8xpu;
            @extend .hx2bzw;
            @extend .ju7ot2;
            line-height: 30px
        }
    }
    .nv5q43{
        @extend .kf1va4;
        img{
            @extend .kf1va4;
        }
    }
    .pa789b{
        @include Web-Size( 100%, 636px );
        background: linear-gradient( 90deg, map-get( $Color, "Light" ) 100%, map-get( $Color, "White" ) 0% );
        .g942i2{
            @extend .ny62tq;
            @extend .ju7ot2;
            color: var(--Web-S6140O);
        }
        .aq6402{
            @extend .flhu41;
            color: var(--Web-S6140O);
            line-height: 11px;
        }
        .q664y3{
            .kn581v{
                @include Web-Size( 100%, 40px );
                @extend .os7ol5;
                @extend .hx2bzw;
                @extend .l9kha6;
                @extend .tfm696;
                @extend .e4yqra;
                border: 1px solid var(--Web-M7S21M);
                font-size: var(--Web-N471BU);
                border-radius: 4px;
                &::placeholder{
                    @extend .hx2bzw;
                    font-size: var(--Web-N471BU);
                }
            }
            .hb0975{
                @include Web-Size( 100%, 40px );
                @extend .os7ol5;
                color: rgba( map-get( $Color, "Black" ), .6 );
                border-color: var(--Web-BKD1HE);
                border-style: solid;
                border-width: 0 12px 0 24px;
                outline: 1px solid var(--Web-M7S21M);
                line-height: 40px
            }
            .j229ex{
                @include Web-Size( 100%, 120px );
                @extend .os7ol5;
                @extend .hx2bzw;
                @extend .l9kha6;
                @extend .tfm696;
                @extend .e4yqra;
                border: 1px solid var(--Web-M7S21M);
                font-size: var(--Web-N471BU);
                border-radius: 4px;
                &::placeholder{
                    @extend .hx2bzw;
                    font-size: var(--Web-N471BU);
                }
            }
            .ph6e87{
                @include Web-Size( 100%, 44px );
                @extend .o3u62u;
                @extend .tfm696;
                @extend .kf1va4;
                @extend .od5yo1;
                @extend .djq82r;
                border-radius: 4px;
                line-height: 44px;
                &:hover{
                    @extend .z82y3r;
                }
            }
            .n003cf{
                @extend .flhu41;
                color: var(--Web-S6140O);
            }
        }
    }
    .r9q627{
        @include Web-Size( 100%, 636px );
        .v394c7{
            @include Web-Size( 100%, 63px );
            border-bottom: 1px dashed var(--Web-O9FU61);
            .yd53f6{
                @extend .flhu41;
                @extend .hx2bzw;
                min-width: 200px;
                line-height: 63px;
                &::after{
                    @extend .h5tmdd;
                    content: ":";
                    font-size: var(--Web-P8H136);
                    margin: 0 0 0 10px;
                }
            }
            .n97th8{
                @extend .flhu41;
                @extend .hx2bzw;
                line-height: 58px
            }
        }
    }
    .j111qi{
        @include Web-Size( 100%, 196px );
        border: 1px solid var(--Web-O9FU61);
        .h5162l{
            @include Web-Size( 96px, 96px );
            @extend .zj2qie;
        }
        .nf25l0{
            @extend .kf1va4;
            .x0j96v{
                @extend .flhu41;
                @extend .hx2bzw;
                line-height: 30px
            }
        }
    }
}
.e70tc8{
    @include Web-Size( 100%, 100% );
    .x49s3g{
        @include Web-Size( 100%, 100% );
    }
}
.b659w7{
    .h804q5{
        @include Web-Size( 100%, 100vh );
        background: rgba( map-get( $Color, "Black" ), .6 );
        padding: 115px 0 0 0;
        z-index: 9999;
        .w898z5{
            min-height: 466px
        }
        .n745hy{
            @extend .os7ol5;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .g537rv{
                .gl6479{
                    @include Web-Size( 100%, 100% );
                    @extend .os7ol5;
                    border-right: 1px solid var(--Web-O9FU61);
                    .r5163w{
                        @extend .z4sfok;
                        .e445c3{
                            @include Web-Size( 100%, 60px );
                            @extend .kf1va4;
                            @extend .tfm696;
                            @extend .e4yqra;
                            @extend .os7ol5;
                            .ja1e68{
                                @extend .hx2bzw;
                                @extend .l9kha6;
                                @extend .flhu41;
                            }
                            .z16h4i{
                                @extend .kf1va4;
                                svg{
                                    @include Web-Size( 17px, 17px );
                                    margin: 1px 0 0 0;
                                }
                            }
                        }
                        .a30e10{
                            background: map-get( $Color, "Light" );
                            border: 0!important;
                        }
                    }
                }
                .t0i4b0{
                    @extend .qv8dnr;
                    .g1t38v{
                        .i0z16z{
                            @extend .kf1va4;
                            .q1a02x{
                                @include Web-Size( 100%, auto );
                                @extend .os7ol5;
                                img{
                                    @include Web-Size( 100%, auto );
                                }
                            }
                            .x6r112{
                                .i3549y{
                                    @extend .flhu41;
                                    @extend .vwei3j;
                                    @extend .ju7ot2;
                                    line-height: 27px
                                }
                                .f70pv9{
                                    @extend .flhu41;
                                    @extend .vwei3j;
                                    @extend .l9kha6;
                                    line-height: 21px
                                }
                            }
                        }
                    }
                    .st39m0{
                        .w2966t{
                            @include Web-Size( 40px, 40px );
                            @extend .kf1va4;
                            @extend .tfm696;
                            @extend .os7ol5;
                            margin: 0 0 40px -20px;
                            border: 1px solid var(--Web-M7S21M);
                            z-index: 12;
                            svg{
                                @include Web-Size( 19px, 19px );
                                margin: .5px 0 0 1.5px;
                                rect{
                                    @extend .kf1va4;
                                    @extend .lk1jon;
                                }
                                path{
                                    @extend .kf1va4;
                                    @extend .lk1jon;
                                }
                            }
                            &:hover{
                                @extend .z82y3r;
                                border: 1px solid map-get( $Color, "Default" );
                                svg path{
                                    @extend .mo9q0r;
                                }
                                svg rect{
                                    @extend .mo9q0r;
                                }
                            }
                        }
                        .a255yx{
                            @include Web-Size( 40px, 40px );
                            @extend .kf1va4;
                            @extend .tfm696;
                            @extend .os7ol5;
                            margin: 0 -20px 40px 0;
                            border: 1px solid var(--Web-M7S21M);
                            z-index: 12;
                            svg{
                                @include Web-Size( 19px, 19px );
                                margin: 0.5px -1.5px 0 0;
                                rect{
                                    @extend .kf1va4;
                                    @extend .lk1jon;
                                }
                                path{
                                    @extend .kf1va4;
                                    @extend .lk1jon;
                                }
                            }
                            &:hover{
                                @extend .z82y3r;
                                border: 1px solid map-get( $Color, "Default" );
                                svg path{
                                    @extend .mo9q0r;
                                }
                                svg rect{
                                    @extend .mo9q0r;
                                }
                            }
                        }
                        .swiper-button-disabled{
                            @extend .zj2qie;
                            border: 1px solid map-get( $Color, "Light" );
                            &:hover{
                                @extend .zj2qie;
                                border: 1px solid map-get( $Color, "Light" );
                                svg{
                                    @include Web-Size( 19px, 19px );
                                    rect{
                                        @extend .lk1jon;
                                    }
                                    path{
                                        @extend .lk1jon;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .q3o8y5{
            @include Web-Size( 100%, 70px );
            @extend .z82y3r;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            .e968bz{
                @include Web-Size( 100%, 70px );
                .v26po1{
                    @include Web-Size( 35px, 35px );
                    @extend .kf1va4;
                    @extend .tfm696;
                    @extend .os7ol5;
                    @extend .vwei3j;
                    @extend .jjp8x6;
                    @extend .vbv58q;
                    line-height: 37px;
                    svg{
                        @extend .kf1va4;
                        @include Web-Size( 18px, 18px );
                        margin: 1px 0 0 1.5px;
                        rect{
                            @extend .kf1va4;
                            @extend .lk1jon;
                        }
                        path{
                            @extend .kf1va4;
                            @extend .lk1jon;
                        }
                    }
                    &:hover svg path{
                        @extend .ke7ifl;
                    }
                    &:hover svg rect{
                        @extend .ke7ifl;
                        @extend .rn36iv;
                    }
                }
                .v188zj{
                    @include Web-Size( 35px, 35px );
                    @extend .kf1va4;
                    @extend .tfm696;
                    @extend .os7ol5;
                    @extend .yc0r22;
                    @extend .jjp8x6;
                    @extend .vbv58q;
                    line-height: 35px;
                    svg{
                        @extend .kf1va4;
                        @include Web-Size( 20px, 20px );
                        margin: 1.5px 0 0 0;
                        g rect{
                            @extend .lk1jon;
                        }
                    }
                    &:hover svg g rect{
                        @extend .kf1va4;
                        @extend .rn36iv;
                        @extend .ke7ifl;
                    }
                }
            }
            .hi362n{
                @include Web-Size( 100%, 73px );
                .h4mz49{
                    @extend .ay35r3;
                    @extend .od5yo1;
                    @extend .jjp8x6;
                    line-height: 70px
                }
            }
        }
    }
}
.m0734c{
    .s70f4d{
        @include Web-Size( 100%, 50px );
        @extend .zj2qie;
        .xj2868{
            @include Web-Size( 100%, 50px );
        }
    }
    .k87517{
        .v013zp{
            .r44t4q{
                @include Web-Size( 100%, 60px );
                border-bottom: 1px solid var(--Web-O9FU61);
                .d97g9t{
                    @extend .uu8xpu;
                    @extend .hx2bzw;
                    @extend .ju7ot2;
                    line-height: 60px
                }
            }
            .d261wo{
                .s827p2{
                    .i1316u{
                        @include Web-Size( 100%, 153px );
                        @extend .zj2qie;
                    }
                    .y69i7d{
                        .w1811y{
                            @extend .uu8xpu;
                            @extend .vwei3j;
                            @extend .ju7ot2;
                        }
                    }
                    &:hover .w1811y{
                        @extend .w6dz2a;
                    }
                }
            }
        }
        .x8lb82{
            .h7361i{
                @include Web-Size( 100%, 50px );
                border-bottom: 1px solid var(--Web-O9FU61);
                .n861vo{
                    @extend .flhu41;
                    @extend .hx2bzw;
                    @extend .ju7ot2;
                    line-height: 50px
                }
            }
            .m8934e{
                @include Web-Size( 100%, 400px );
                @extend .tfm696;
            }
            .tr362m{
                @extend .tfm696;
                svg{
                    @include Web-Size( 16px, 16px );
                    margin: 0 0 2px 0;
                }
            }
        }
        .z6m3n3{
            @include Web-Size( 100%, auto );
            .rg5423{
                .swiper{
                    .swiper-slide img{
                        @include Web-Size( 100%, auto );
                    }
                }
                .r3081r{
                    .of320a{
                        @include Web-Size( 40px, 40px );
                        @extend .zj2qie;
                        @extend .tfm696;
                        @extend .kf1va4;
                        z-index: 1;
                        &:hover{
                            @extend .mzm3ni;
                            svg{
                                rect{
                                    @extend .mo9q0r;
                                    @extend .kf1va4;
                                }
                                path{
                                    @extend .mo9q0r;
                                    @extend .kf1va4;
                                }
                            }
                        }
                        svg{
                            @include Web-Size( 18px, 18px );
                            @extend .kf1va4;
                            margin: -1px 0 0 0;
                        }
                    }
                }
            }
        }
        .g0h616{
            .uh95c8{
                @extend .hx2bzw;
                @extend .jjp8x6;
                @extend .ny62tq;
            }
            .o5m9z5{
                @include Web-Size( 33.3%, auto );
                .k51ma6{
                    @extend .flhu41;
                    @extend .n25600;
                    @extend .ju7ot2;
                    line-height: 26px;
                    &::before{
                        content: "Marka :";
                        margin: 0 5px 0 0;
                    }
                }
                .fmb269{
                    @extend .flhu41;
                    @extend .n25600;
                    @extend .ju7ot2;
                    line-height: 26px;
                    &::before{
                        content: "Model :";
                        margin: 0 5px 0 0;
                    }
                }
                .t35d8f{
                    @extend .flhu41;
                    @extend .n25600;
                    @extend .ju7ot2;
                    line-height: 26px;
                    &::before{
                        content: "kW :";
                        margin: 0 5px 0 0;
                    }
                }
            }
            .w28f14{
                border-top: 1px solid var(--Web-O9FU61);
                h2{
                    @extend .g1abzu;
                    @extend .a7j6j1;
                    @extend .rtfe20;
                    @extend .ay35r3;
                    @extend .hx2bzw;
                    @extend .jjp8x6;
                    margin: 24px 0 5px 0;
                    line-height: 24px;
                }
                p{
                    @extend .g1abzu;
                    @extend .a7j6j1;
                    @extend .hx2bzw;
                    @extend .flhu41;
                    @extend .h0m3h5;
                    @extend .ictt32;
                    padding: 10px 0 0 0;
                    line-height: 28px;
                }
            }
            .i03i16{
                border-top: 1px solid var(--Web-O9FU61);
                .ht88c1{
                    .a3158z{
                        @include Web-Size( 100%, auto );
                        @extend .flhu41;
                        @extend .hx2bzw;
                        &::before{
                            @extend .g1abzu;
                            content: url( "./Media/Icon/011.svg" );
                            margin: 0 10px 0 0;
                        }
                    }
                }
            }
            .b40pk8{
                border-top: 1px solid var(--Web-O9FU61);
                .e7877w{
                    @extend .wd9i3m;
                    @extend .tfm696;
                    @extend .kf1va4;
                    .h4831h{
                        @include Web-Size( 100%, 54px );
                        border-bottom: 1px solid var(--Web-O9FU61);
                        .x1nl29{
                            @extend .flhu41;
                            @extend .vwei3j;
                            line-height: 54px;
                        }
                        .wqq462{
                            svg{
                                @include Web-Size( 17px, 17px );
                            }
                        }
                    }
                    .u7627c{
                        @extend .kf1va4;
                        .vk563m{
                            @extend .flhu41;
                            @extend .n25600;
                            line-height: 27px;
                        }
                    }
                }
            }
        }
    }
}
/* Footer */
.z1e890{
    @extend .o3u62u;
    .obbx5v{
        @include Web-Size( 100%, 85px );
        border-bottom: 1px solid rgba( map-get( $Color, "White" ), .05 );
        .s59k83{
            @include Web-Size( 100%, 85px );
            .pir95i{
                @extend .z4sfok;
                li{
                    a{
                        @include Web-Size( auto, 33px );
                        @extend .px0inf;
                        @extend .kf1va4;
                        font-size: var(--Web-B0JI04);
                        margin: 0 0 0 35.7px;
                        border: 1px solid rgba( map-get( $Color, "White" ), .05 );
                        line-height: 33px;
                        &:hover{
                            border: 1px solid rgba( map-get( $Color, "White" ), .1 );
                            @extend .od5yo1;
                        }
                    }
                    &:first-child a{
                        @extend .h0m3h5;
                    }
                }
            }
        }
    }
    .db75du{
        @include Web-Size( 100%, auto );
        .f630xx{
            @extend .flhu41;
            @extend .od5yo1;
            @extend .ju7ot2;
        }
        .f6be59{
            @extend .tfm696;
            @extend .px0inf;
            font-size: var(--Web-N471BU);
            line-height: 25px
        }
        .za3wuz{
            @extend .kjx24w;
            @extend .tfm696;
            font-size: var(--Web-03);
            line-height: 22px
        }
        .a2kp8i{
            @include Web-Size( 100%, auto );
            .o6v2y8{
                @include Web-Size( 45px, 45px );
                border: 1px solid rgba( map-get( $Color, "White" ), .05 );
                svg{
                    @include Web-Size( 24px, 24px );
                    path[fill]{
                        @extend .auu562;
                    }
                    circle[fill]{
                        @extend .auu562;
                    }
                    rect[fill]{
                        @extend .auu562;
                    }
                    polygon[fill]{
                        @extend .auu562;
                    }
                }
            }
            .p268gl{
                @include Web-Size( 45px, 45px );
                border: 1px solid rgba( map-get( $Color, "White" ), .05 );
                svg{
                    @include Web-Size( 24px, 24px );
                    path[fill]{
                        @extend .auu562;
                    }
                    circle[fill]{
                        @extend .auu562;
                    }
                    rect[fill]{
                        @extend .auu562;
                    }
                    polygon[fill]{
                        @extend .auu562;
                    }
                }
            }
            .w9b4zl{
                @include Web-Size( 45px, 45px );
                border: 1px solid rgba( map-get( $Color, "White" ), .05 );
                svg{
                    @include Web-Size( 24px, 24px );
                    path[fill]{
                        @extend .auu562;
                    }
                    circle[fill]{
                        @extend .auu562;
                    }
                    rect[fill]{
                        @extend .auu562;
                    }
                    polygon[fill]{
                        @extend .auu562;
                    }
                }
            }
            .iej09i{
                padding: 0 0 0 45px;
            }
        }
        .h5zzvk{
            @extend .z4sfok;
            li{
                a{
                    @extend .kf1va4;
                    @extend .rtfe20;
                    @extend .px0inf;
                    font-size: var(--Web-N471BU);
                    margin: 0 0 15px 0;
                    line-height: 19px;
                    &:hover{
                        @extend .od5yo1;
                    }
                }
            }
        }
    }
    .x4vs5d{
        @include Web-Size( 100%, 70px );
        .e56wqh{
            @include Web-Size( 100%, 70px );
            border-top: 1px solid rgba( map-get( $Color, "White" ), .05 );
            .yr22aj{
                @include Web-Size( 36px, 36px );
                @extend .t11c49;
                @extend .kf1va4;
                border: 1px solid rgba( map-get( $Color, "White" ), .05 );
                svg{
                    @include Web-Size( 20.5px, 20.5px );
                    g [fill]{
                        @extend .kf1va4;
                        @extend .auu562;
                    }
                }
                &:hover{
                    border: 1px solid rgba( map-get( $Color, "White" ), .1 );
                    svg{
                        g [fill]{
                            @extend .kf1va4;
                            @extend .auu562;
                            @extend .rn36iv;
                        }
                    }
                }
            }
            .z4mie7{
                @include Web-Size( 36px, 36px );
                @extend .o3u62u;
                @extend .t11c49;
                @extend .kf1va4;
                border: 1px solid rgba( map-get( $Color, "White" ), .05 );
                svg{
                    @include Web-Size( 27px, 27px );
                    @extend .p9wjuz;
                    margin: -1px 0 0 0;
                    g [fill]{
                        @extend .kf1va4;
                        @extend .auu562;
                    }
                }
                &:hover{
                    border: 1px solid rgba( map-get( $Color, "White" ), .2 );
                    svg{
                        g [fill]{
                            @extend .kf1va4;
                            @extend .auu562;
                            @extend .rn36iv;
                        }
                    }
                }
            }
            .ih8riw{
                @extend .z4sfok;
                .f5qfag{
                    @include Web-Size( 36px, 36px );
                    @extend .t11c49;
                    @extend .kf1va4;
                    border: 1px solid rgba( map-get( $Color, "White" ), .05 );
                    svg{
                        @include Web-Size( 23px, 23px );
                    }
                    &:hover{
                        border: 1px solid rgba( map-get( $Color, "White" ), .2 );
                        svg{
                            #RD0K57{
                                @extend .kf1va4;
                                @extend .mo9q0r;
                            }
                        }
                    }
                }
                .wxam0p{
                    @include Web-Size( 36px, 36px );
                    @extend .t11c49;
                    @extend .kf1va4;
                    border: 1px solid rgba( map-get( $Color, "White" ), .05 );
                    svg{
                        @include Web-Size( 22px, 22px );
                    }
                    &:hover{
                        border: 1px solid rgba( map-get( $Color, "White" ), .2 );
                        svg{
                            #D769DD{
                                @extend .kf1va4;
                                @extend .mo9q0r;
                            }
                            #N1ZKBQ{
                                @extend .kf1va4;
                                @extend .mo9q0r;
                            }
                        }
                    }
                }
                .olle9e{
                    @include Web-Size( 36px, 36px );
                    @extend .t11c49;
                    @extend .kf1va4;
                    border: 1px solid rgba( map-get( $Color, "White" ), .05 );
                    svg{
                        @include Web-Size( 24px, 24px );
                    }
                    &:hover{
                        border: 1px solid rgba( map-get( $Color, "White" ), .2 );
                        svg{
                            #OA8ZAT{
                                @extend .kf1va4;
                                @extend .mo9q0r;
                            }
                            #H46QOE{
                                @extend .kf1va4;
                                @extend .mo9q0r;
                            }
                        }
                    }
                }
            }
        } 
    }
    .r4z6vm{
        @include Web-Size( 100%, 50px );
        @extend .zj2qie;
        .e7v3f0{
            @extend .n25600;
            @extend .kf1va4;
            @extend .flhu41;
            line-height: 50px;
        }
        .d48yli{
            @extend .z4sfok;
            li{
                a{
                    @extend .n25600;
                    @extend .kf1va4;
                    @extend .flhu41;
                    line-height: 50px;
                    &::before{
                        @extend .n25600;
                        content: "•";
                        padding: 0 12px;
                    }
                    &:hover{
                        @extend .vwei3j;
                    }
                }
                &:first-child a{
                    &::before{
                        @extend .rtfe20;
                        @extend .fap9z1;
                    }
                }
            }
        }
    }
    .z9e62w{
        @extend .qv8dnr;
        z-index: 999;
        .h6o36k{
            .jp7b74{
                @include Web-Size( 100%, 50px );
                border-top: 1px solid var(--Web-D6X5A4);
                .t750s6{
                    @extend .kf1va4;
                    @extend .tfm696;
                    .h215h1{
                        @extend .flhu41;
                        @extend .hx2bzw;
                        line-height: 50px
                    }
                    .q16cz9{
                        @extend .flhu41;
                        @extend .hx2bzw;
                        line-height: 50px
                    }
                }
            }
            .o3e49b{
                @include Web-Size( 100%, 55px );
                @extend .o3u62u;
                .k2d228{
                    .h906vf{
                        @include Web-Size( 100%, 55px );
                        @extend .tfm696;
                        @extend .kf1va4;
                        svg{
                            @include Web-Size( 20px, 20px );
                            path{
                                @extend .mo9q0r;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Animation */
@keyframes Menu{
    from{ 
        top: 0
    }
    to{ 
        top: -5px 
    }
}
@keyframes Slide{
    from{ 
        left: 0
    }
    to{ 
        left: 5px
    }
}