@media (min-width:240px) and (max-width:319px){
    main{ margin-bottom: 120px!important }
    .x3287n, .g67d7f, .obbx5v, .db75du, .x4vs5d, .r4z6vm{ display: none!important }
    .x80247, .z9e62w{ display: block!important }
    .h215h1{ font-size: 15px!important }
    .h50iu1{ font-size: 14px!important }
    .q16cz9{ font-size: 14.5px!important }
    .tz9913 svg{ width: 18px!important }
    .tz9913 svg{ height: 18px!important }
    .b03th6{ font-size: 15.5px!important }
    .x09f37, .t2f180{ display: none!important }
    .h1r483, .b62125, .h1r483::before, .b62125::before, .e9l174, .a82g3r{ display: block!important }
    .h1r483, .b62125{ float: none!important }
    .h1r483, .b62125{ height: auto!important }
    .h1r483::before, .b62125::before{ content: "" }
    .h1r483::before, .b62125::before, .zk98z9 img, .k3039j img, .ub6472 img, .s33610 img, .s33610{ width: 100%!important }
    .we012g, .zk98z9 img, .k3039j, .k3039j img, .ub6472, .ub6472 img, .te668i iframe, .te668i, .s33610 img, .s33610, .t90i22{ height: auto!important }
    .h1r483::before, .b62125::before{ padding-top: 58%!important }
    .y8017f{ box-shadow: none!important }
    .x09f37, .t2f180, .e9l174, .n2017b, .c8g6r0{ max-width: 100%!important }
    .v986gy, .e80c17, .y8017f, .we012g{ margin: 15px 0 0 0!important }
    .t2f180, .x57506, .n2017b, .u97139{ margin: 10px 0 0 0!important }
    .t2f180{ font-size: 15px!important }
    .e9l174, .n2017b{ font-size: 16px!important }
    .x09f37{ font-size: 17px!important }
    .x09f37, .n2017b{ line-height: 26px!important }
    .x09f37{ font-weight: 500!important }
    .t2f180{ line-height: 26px!important }
    .e9l174, .c8g6r0{ overflow: hidden }
    .e9l174, .c8g6r0{ white-space: nowrap }
    .e9l174, .c8g6r0{ text-overflow: ellipsis }
    .x57506{ height: 48px!important }
    .x57506{ border-bottom: 1px solid var(--Web-O9FU61) }
    .y3vo86, .m0p16l{ line-height: 48px!important }
    .b62125::before{ padding-top: 100%!important }
    .y6rh29{ font-size: 17px!important }
    .y6rh29{ line-height: 26px!important }
    .zk98z9{ width: 40px!important }
    .zk98z9{ height: 40px!important }
    .zk98z9 img{ width: 20px!important }
    .zk98z9 img{ height: 20px!important }
    .h2314u{ margin: 15px 0 0 0!important }
    .c0046v{ margin: 10px 0 0 0!important }
    .c0046v, .n1945q, .y5x1g0{ height: auto!important }
    .c0046v::before{ height: auto!important }
    .q26962, .d8t671{ font-size: 19px!important }
    .q26962, .d8t671{ text-align: center!important }
    .d8t671{ line-height: 27px!important }
    .d8t671{ margin: 4px 0 0 0!important }
    .c0046v{ background: transparent!important }
    .c0046v::before, .q26962, .d8t671, .r66d51, .n1945q{ display: none!important }
    .p594hm{ padding-left: 0!important }
    .p594hm{ padding-right: 0!important }
    .n9v17b{ font-size: 17px!important }
    .w9z2e0{ margin: 6px 0 0 0!important }
    .w9z2e0{ font-size: 15px!important }
    .y5x1g0{ padding: 0!important }
    .y5x1g0{ margin: 6px 0 0 0!important }
    .k43h8o{ font-size: 17px!important }
    .q6510h{ padding: 10px 0 0 0!important }
    .e2lm99{ font-size: 15px!important }
    .e2lm99{ line-height: 25px!important }
    .ss1j85{ margin: 6px 0 0 0!important }
    .te668i, .nxl288{ margin: 10px 0 0 0!important }
    .te668i{ height: auto!important }
    .te668i iframe{ height: auto!important }
    .s624xi{ font-size: 16px!important }
    .r3u8c9{ font-size: 15px!important }
    .r3u8c9{ line-height: 23px!important }
    .nxl288{ padding: 20px!important }
    .s3704x, .af8k72{ margin: 14px 0 0 0!important }
    .h84059{ margin: 14px 0!important }
    .u763kh{ line-height: 1!important }
    .n454f7{ border: 0!important }
    .n454f7{ padding: 0!important }
    .r3u8c9{ display: none!important }
    .p750g9{ font-size: 16px!important }
    .p750g9{ overflow: hidden!important }
    .p750g9{ text-overflow: ellipsis!important }
    .p750g9{ white-space: nowrap!important }
    .q98p51{ height: auto!important }
    .q98p51{ float: none!important }
    .q98p51{ display: block!important }
    .q98p51::before{ padding-top: 100%!important }
    .q98p51::before{ width: 100%!important }
    .q98p51::before{ display: block!important }
    .q98p51::before{ content: "" }
    .oi4351{ text-align: center!important }
    .oi4351{ font-size: 15px!important }
    .e22q94{ height: 60px!important }
    .z272kl li span{ max-width: 100px }
    .z272kl li span, .g942i2{ overflow: hidden }
    .z272kl li span, .g942i2{ white-space: nowrap }
    .z272kl li span, .g942i2{ text-overflow: ellipsis }
    .nv5q43{ margin: 10px 0 0 0!important }
    .aq6402{ margin: 5px 0 0 0!important }
    .nv5q43 img{ width: 100% }
    .nv5q43 img{ height: auto }
    .i02f1u{ margin: 25px 0 0 0!important }
    .i02f1u h6{ text-align: center!important }
    .i02f1u h6{ font-size: 16px!important }
    .i02f1u h6{ line-height: 25px!important }
    .i02f1u p{ text-align: center!important }
    .i02f1u p{ font-size: 15px!important }
    .i02f1u p{ line-height: 26px!important }
    .i02f1u{ padding: 0!important }
    .g942i2{ line-height: 26px!important }
    .pa789b{ height: auto!important }
    .pa789b{ padding: 30px 20px!important }
    .q664y3{ margin: 0!important }
    .kn581v, .j229ex, .hb0975{ margin: 14px 0 0 0!important }
    .o6l4h3{ margin-bottom: 0!important }
    .f1j45n{ height: 35px!important }
    .z272kl li .zoq741{ margin-left: 4px!important }
    .z272kl li .zoq741{ margin-right: 4px!important }
    .o6l4h3 .row .col-md-2{ width: 50%!important }
    .j111qi{ height: auto!important }
    .j111qi img, .h5162l{ width: 100%!important }
    .j111qi img, .h5162l{ height: auto!important }
    .j111qi{ padding: 20px 20px!important }
    .h5162l{ background: transparent!important }
    .x0j96v{ line-height: 20px!important }
    .x0j96v{ overflow: hidden }
    .x0j96v{ text-overflow: ellipsis }
    .x0j96v{ white-space: nowrap }
    .nf25l0{ padding: 0!important }
    .j111qi{ margin: 20px 0 0 0!important }
    .o6l4h3{ margin-top: 0!important }
    .zv2t48{ display: none!important }
    .zi8021{ padding: 0!important }
    .d593vy{ margin: 14px 0 0 0!important }
    .eb068o{ padding: 0 15px!important }
    .go895o{ width: 90%!important }
    .go895o{ overflow: hidden }
    .go895o{ white-space: nowrap }
    .go895o{ text-overflow: ellipsis }
    .pg153m{ width: 10%!important }
    .go895o, .n4yo21{ font-size: 15px!important }
    .go895o{ line-height: 50px!important }
    .n4yo21{ line-height: 27px!important }
    .n4yo21{ text-align: center!important }
    .v5x9d3{ padding-bottom: 32%!important }
    .v5x9d3, .r5ta95{ float: none!important }
    .v5x9d3, .r5ta95{ display: block!important }
    .v5x9d3{ margin: 10px 0 5px 0!important }
    .r5ta95, .h9514u, .o3164p, .q7j578, .p3i5n5, .m65434, .z19w39, .oap905, .i501tu{ height: 100%!important }
    .r5ta95, .h9514u, .o3164p, .q7j578, .p3i5n5, .m65434, .z19w39, .oap905, .i501tu{ position: absolute!important }
    .gx003r, .d233cz{ width: auto!important }
    .gx003r{ font-weight: 600!important }
    .gx003r{ font-size: 16px!important }
    .d233cz{ font-size: 15px!important }
    .d233cz{ line-height: 22px!important }
    .mo943y{ justify-content: space-between!important }
    .mo943y{ flex-direction: row!important }
    .mo943y{ padding: 0 20px!important }
    .gx003r{ max-width: 50%!important }
    .gx003r{ overflow: hidden }
    .gx003r{ text-overflow: ellipsis }
    .gx003r{ white-space: nowrap }
    .o6l4h3{ margin-bottom: 0!important }
    .v26po1, .v188zj{ width: 30px!important }
    .v26po1, .v188zj{ height: 30px!important }
    .v26po1{ line-height: 32px!important }
    .v188zj{ line-height: 30px!important }
    .v26po1 svg{ width: 16px!important }
    .v26po1 svg{ height: 16px!important }
    .v26po1 svg{ margin: 1px 0 0 1px!important }
    .v188zj svg{ width: 17px!important }
    .v188zj svg{ height: 17px!important }
    .q3o8y5{ padding: 0 20px!important }
    .h4mz49{ font-size: 16px!important }
    .h4mz49{ overflow: hidden }
    .h4mz49{ white-space: nowrap }
    .h4mz49{ text-overflow: ellipsis }
    .h804q5{ padding: 0!important }
    .r5163w{ padding: 0!important }
    .e445c3{ padding: 0 20px!important }
    .ja1e68{ max-width: 50%!important }
    .ja1e68{ overflow: hidden!important }
    .ja1e68{ text-overflow: ellipsis!important }
    .ja1e68{ white-space: nowrap!important }
    .gl6479{ border-right: 0!important }
    .gl6479{ border-bottom: 1px solid var(--Web-O9FU61) }
    .gl6479{ padding-bottom: 5px!important }
    .t0i4b0{ padding: 20px 0!important }
    .x6r112{ padding: 0 20px!important }
    .i3549y{ font-size: 15px!important }
    .w2966t, .a255yx{ width: 30px!important }
    .w2966t, .a255yx{ height: 30px!important }
    .a255yx svg, .w2966t svg{ width: 16px!important }
    .a255yx svg, .w2966t svg{ height: 16px!important }
    .a255yx{ margin: 0 -10px 40px 0!important }
    .w2966t{ margin: 0 0 40px -10px!important }
    .z272kl li:nth-child(4), .z272kl li:nth-child(5), .z272kl li:nth-child(6), .h7361i{ display: none!important }
    .of320a{ width: 32px!important }
    .of320a{ height: 32px!important }
    .of320a svg, .wqq462 svg{ width: 16px!important }
    .of320a svg, .wqq462 svg{ height: 16px!important }
    .of320a svg{ margin: 0!important }
    .m8934e, .g0h616, .w28f14, .i03i16{ margin: 14px 0!important }
    .uh95c8, .k51ma6, .fmb269, .t35d8f, .w28f14 h2, .w28f14 p, .a3158z, .t35d8f::before, .fmb269::before, .k51ma6::before, .x1nl29, .vk563m{ font-size: 15px!important }
    .o5m9z5, .w28f14 p{ margin: 4px 0 0 0!important }
    .a3158z{ margin: 15px 0 0 0!important }
    .y69i7d{ margin: 8px 0 0 0!important }
    .w28f14 h2, .w28f14 p{ text-align: center!important }
    .w28f14 p{ padding: 4px 0 0 0!important }
    .a3158z::before{ width: 18px!important }
    .a3158z::before{ height: 18px!important }
    .m8934e{ height: auto!important }
    .a3158z, .vk563m{ text-align: center!important }
    .r44t4q{ height: 50px!important }
    .d97g9t{ line-height: 50px!important }
    .b40pk8, .v013zp{ margin-bottom: 0!important }
    .i1316u{ height: auto!important }
    .i1316u{ background: transparent!important }
    .i1316u img{ width: 100% }
    .i1316u img{ height: auto }
    .o6l4h3{ height: auto!important }
    .g942i2{ font-size: 16px!important }
    .aq6402{ font-size: 15px!important }
    .aq6402{ line-height: 25px!important }
    .ph6e87{ margin: 14px 0 0 0!important }
    .q98p51{ margin: 0!important }
    .zk286n{ margin: 14px 0 80px 0!important }
}
@media (min-width:320px) and (max-width:480px){
    main{ margin-bottom: 120px!important }
    .x3287n, .g67d7f, .obbx5v, .db75du, .x4vs5d, .r4z6vm{ display: none }
    .x80247, .z9e62w, .e9l174, .a82g3r{ display: block!important }
    .h50iu1{ font-size: 14px!important }
    .tz9913 svg{ width: 18px!important }
    .tz9913 svg{ height: 18px!important }
    .b03th6{ font-size: 16px!important }
    .x09f37, .t2f180{ display: none!important }
    .h1r483, .b62125, .h1r483::before, .b62125::before{ display: block!important }
    .h1r483, .b62125{ float: none!important }
    .h1r483, .b62125{ height: auto!important }
    .h1r483::before, .b62125::before{ content: "" }
    .h1r483::before, .b62125::before, .zk98z9 img, .k3039j img, .ub6472 img, .s33610 img, .s33610{ width: 100%!important }
    .we012g, .zk98z9 img, .k3039j, .k3039j img, .ub6472, .ub6472 img, .te668i iframe, .te668i, .s33610 img, .s33610, .t90i22{ height: auto!important }
    .h1r483::before{ padding-top: 58%!important }
    .y8017f{ box-shadow: none!important }
    .x09f37, .t2f180, .n2017b{ max-width: 100%!important }
    .v986gy, .e80c17, .t90i22, .a82g3r{ margin: 15px 0 0 0!important }
    .t2f180, .x57506, .n2017b, .u97139, .y8017f{ margin: 10px 0 0 0!important }
    .t2f180, .n2017b{ font-size: 16px!important }
    .x09f37, .e9l174{ font-size: 17px!important }
    .x09f37{ line-height: 26px!important }
    .x09f37{ overflow: hidden }
    .x09f37{ white-space: nowrap }
    .x09f37{ text-overflow: ellipsis }
    .t2f180, .n2017b{ line-height: 26px!important }
    .a82g3r{ line-height: 27px!important }
    .x57506{ height: 48px!important }
    .x57506{ border-bottom: 1px solid var(--Web-O9FU61) }
    .y3vo86, .m0p16l{ line-height: 48px!important }
    .b62125::before{ padding-top: 100%!important }
    .y6rh29{ font-size: 17px!important }
    .y6rh29{ line-height: 27px!important }
    .zk98z9{ width: 45px!important }
    .zk98z9{ height: 45px!important }
    .zk98z9 img{ width: 22px!important }
    .zk98z9 img{ height: 22px!important }
    .h2314u{ margin: 15px 0 0 0!important }
    .c0046v, .s624xi{ margin: 10px 0 0 0!important }
    .c0046v, .n1945q, .y5x1g0{ height: auto!important }
    .c0046v::before{ height: auto!important }
    .q26962, .d8t671{ font-size: 19px!important }
    .q26962, .d8t671{ text-align: center!important }
    .d8t671{ line-height: 27px!important }
    .d8t671{ margin: 4px 0 0 0!important }
    .c0046v{ background: transparent!important }
    .c0046v::before, .q26962, .d8t671, .r66d51, .n1945q{ display: none!important }
    .p594hm{ padding-left: 0!important }
    .p594hm{ padding-right: 0!important }
    .n9v17b{ font-size: 17px!important }
    .w9z2e0{ margin: 6px 0 0 0!important }
    .w9z2e0{ font-size: 15px!important }
    .y5x1g0{ padding: 0!important }
    .y5x1g0{ margin: 6px 0 0 0!important }
    .k43h8o{ font-size: 17px!important }
    .q6510h{ padding: 10px 0 0 0!important }
    .e2lm99{ font-size: 15px!important }
    .e2lm99{ line-height: 25px!important }
    .ss1j85{ margin: 6px 0 0 0!important }
    .te668i, .nxl288{ margin: 10px 0 0 0!important }
    .te668i{ height: auto!important }
    .te668i iframe{ height: auto!important }
    .s624xi{ font-size: 17px!important }
    .r3u8c9{ font-size: 15px!important }
    .h3t02u{ font-size: 16px!important }
    .r3u8c9, .h3t02u{ line-height: 25px!important }
    .nxl288{ padding: 20px!important }
    .s3704x, .af8k72{ margin: 14px 0 0 0!important }
    .h84059{ margin: 14px 0!important }
    .r3u8c9, .h3t02u{ margin: 8px 0 0 0!important }
    .h3t02u{ overflow: hidden }
    .h3t02u{ white-space: nowrap }
    .h3t02u{ text-overflow: ellipsis }
    .u763kh{ line-height: 1!important }
    .n454f7{ border: 0!important }
    .n454f7{ padding: 20px 20px!important }
    .r3u8c9{ display: none!important }
    .p750g9{ font-size: 17px!important }
    .p750g9{ overflow: hidden!important }
    .p750g9{ text-overflow: ellipsis!important }
    .p750g9{ white-space: nowrap!important }
    .q98p51{ height: auto!important }
    .q98p51{ float: none!important }
    .q98p51{ display: block!important }
    .q98p51::before{ padding-top: 100%!important }
    .q98p51::before{ width: 100%!important }
    .q98p51::before{ display: block!important }
    .q98p51::before{ content: "" }
    .oi4351{ text-align: center!important }
    .oi4351{ font-size: 15px!important }
    .e22q94{ height: 60px!important }
    .z272kl li span{ max-width: 120px }
    .z272kl li span, .g942i2{ overflow: hidden }
    .z272kl li span, .g942i2{ white-space: nowrap }
    .z272kl li span, .g942i2{ text-overflow: ellipsis }
    .nv5q43{ margin: 10px 0 0 0!important }
    .nv5q43 img{ width: 100% }
    .nv5q43 img{ height: auto }
    .i02f1u{ margin: 25px 0 0 0!important }
    .i02f1u h6{ text-align: center!important }
    .i02f1u h6{ font-size: 17px!important }
    .i02f1u p{ text-align: center!important }
    .i02f1u p{ font-size: 16px!important }
    .i02f1u p{ line-height: 28px!important }
    .i02f1u p{ margin: 14px 0 0 0!important }
    .i02f1u{ padding: 0!important }
    .g942i2{ font-size: 18px!important }
    .aq6402{ font-size: 16px!important }
    .g942i2{ line-height: 26px!important }
    .aq6402{ line-height: 25px!important }
    .pa789b{ height: auto!important }
    .pa789b{ padding: 30px 20px!important }
    .q664y3{ margin: 0!important }
    .kn581v, .j229ex, .hb0975{ margin: 14px 0 0 0!important }
    .o6l4h3{ margin-bottom: 0!important }
    .aq6402{ margin: 8px 0 0 0!important }
    .f1j45n{ height: 35px!important }
    .z272kl li span{ margin-left: 4px!important }
    .z272kl li span{ margin-right: 4px!important }
    .o6l4h3 .row .col-md-2{ width: 50%!important }
    .j111qi{ height: auto!important }
    .j111qi img, .h5162l{ width: 100%!important }
    .j111qi img, .h5162l{ height: auto!important }
    .j111qi{ padding: 25px 20px!important }
    .h5162l{ background: transparent!important }
    .x0j96v{ line-height: 20px!important }
    .x0j96v{ overflow: hidden }
    .x0j96v{ text-overflow: ellipsis }
    .x0j96v{ white-space: nowrap }
    .nf25l0{ padding: 0!important }
    .j111qi{ margin: 20px 0 0 0!important }
    .o6l4h3{ margin-top: 0!important }
    .zv2t48{ display: none!important }
    .zi8021{ padding: 0!important }
    .d593vy{ margin: 14px 0 0 0!important }
    .eb068o{ padding: 0 15px!important }
    .go895o{ width: 90%!important }
    .go895o{ overflow: hidden }
    .go895o{ white-space: nowrap }
    .go895o{ text-overflow: ellipsis }
    .pg153m{ width: 10%!important }
    .go895o, .n4yo21{ font-size: 15px!important }
    .go895o{ line-height: 50px!important }
    .n4yo21{ line-height: 27px!important }
    .n4yo21{ text-align: center!important }
    .v5x9d3{ padding-bottom: 29%!important }
    .v5x9d3, .r5ta95{ float: none!important }
    .v5x9d3, .r5ta95{ display: block!important }
    .v5x9d3{ margin: 10px 0 5px 0!important }
    .r5ta95, .h9514u, .o3164p, .q7j578, .p3i5n5, .m65434, .z19w39, .oap905, .i501tu{ height: 100%!important }
    .r5ta95, .h9514u, .o3164p, .q7j578, .p3i5n5, .m65434, .z19w39, .oap905, .i501tu{ position: absolute!important }
    .gx003r, .d233cz{ width: auto!important }
    .gx003r{ font-weight: 600!important }
    .gx003r{ font-size: 16px!important }
    .d233cz{ font-size: 15px!important }
    .d233cz{ line-height: 22px!important }
    .mo943y{ justify-content: space-between!important }
    .mo943y{ flex-direction: row!important }
    .mo943y{ padding: 0 20px!important }
    .gx003r{ max-width: 50%!important }
    .gx003r{ overflow: hidden }
    .gx003r{ text-overflow: ellipsis }
    .gx003r{ white-space: nowrap }
    .o6l4h3{ margin-bottom: 0!important }
    .v26po1, .v188zj{ width: 30px!important }
    .v26po1, .v188zj{ height: 30px!important }
    .v26po1{ line-height: 32px!important }
    .v188zj{ line-height: 30px!important }
    .v26po1 svg{ width: 16px!important }
    .v26po1 svg{ height: 16px!important }
    .v26po1 svg{ margin: 1px 0 0 1px!important }
    .v188zj svg{ width: 17px!important }
    .v188zj svg{ height: 17px!important }
    .q3o8y5{ padding: 0 20px!important }
    .h4mz49{ font-size: 16px!important }
    .h4mz49{ overflow: hidden }
    .h4mz49{ white-space: nowrap }
    .h4mz49{ text-overflow: ellipsis }
    .h804q5{ padding: 0!important }
    .r5163w{ padding: 0!important }
    .e445c3{ padding: 0 20px!important }
    .ja1e68{ max-width: 50%!important }
    .ja1e68{ overflow: hidden!important }
    .ja1e68{ text-overflow: ellipsis!important }
    .ja1e68{ white-space: nowrap!important }
    .gl6479{ border-right: 0!important }
    .gl6479{ border-bottom: 1px solid var(--Web-O9FU61) }
    .gl6479{ padding-bottom: 5px!important }
    .t0i4b0{ padding: 20px 0!important }
    .x6r112{ padding: 0 20px!important }
    .i3549y{ font-size: 15.5px!important }
    .w2966t, .a255yx{ width: 30px!important }
    .w2966t, .a255yx{ height: 30px!important }
    .a255yx svg, .w2966t svg{ width: 16px!important }
    .a255yx svg, .w2966t svg{ height: 16px!important }
    .a255yx{ margin: 0 -10px 40px 0!important }
    .w2966t{ margin: 0 0 40px -10px!important }
    .z272kl li:nth-child(4), .z272kl li:nth-child(5), .z272kl li:nth-child(6), .h7361i{ display: none!important }
    .of320a{ width: 32px!important }
    .of320a{ height: 32px!important }
    .of320a svg, .wqq462 svg{ width: 16px!important }
    .of320a svg, .wqq462 svg{ height: 16px!important }
    .of320a svg{ margin: 0!important }
    .m8934e, .g0h616, .w28f14, .i03i16{ margin: 14px 0!important }
    .uh95c8, .k51ma6, .fmb269, .t35d8f, .w28f14 h2, .w28f14 p, .a3158z, .t35d8f::before, .fmb269::before, .k51ma6::before, .x1nl29, .vk563m{ font-size: 16px!important }
    .o5m9z5, .w28f14 p{ margin: 4px 0 0 0!important }
    .a3158z{ margin: 15px 0 0 0!important }
    .y69i7d{ margin: 8px 0 0 0!important }
    .w28f14 h2, .w28f14 p{ text-align: start!important }
    .w28f14 p{ padding: 4px 0 0 0!important }
    .a3158z::before{ width: 18px!important }
    .a3158z::before{ height: 18px!important }
    .m8934e{ height: auto!important }
    .a3158z, .vk563m{ text-align: start!important }
    .r44t4q{ height: 50px!important }
    .d97g9t{ line-height: 50px!important }
    .b40pk8, .v013zp{ margin-bottom: 0!important }
    .i1316u{ height: auto!important }
    .i1316u{ background: transparent!important }
    .i1316u img{ width: 100% }
    .i1316u img{ height: auto }
    .ph6e87{ margin: 14px 0 0 0!important }
    .q98p51{ margin: 0!important }
    .zk286n{ margin: 14px 0 80px 0!important }
}
@media (min-width:481px) and (max-width:767px){
    main{ margin-bottom: 105px!important }
    .x3287n, .g67d7f, .obbx5v, .db75du, .x4vs5d, .r4z6vm{ display: none }
    .x80247, .z9e62w, .e9l174, .a82g3r{ display: block!important }
    .h50iu1{ font-size: 14px!important }
    .tz9913 svg{ width: 18px!important }
    .tz9913 svg{ height: 18px!important }
    .b03th6{ font-size: 17px!important }
    .x09f37, .t2f180{ display: none!important }
    .h1r483, .b62125, .h1r483::before, .b62125::before{ display: block!important }
    .h1r483, .b62125{ float: none!important }
    .h1r483, .b62125{ height: auto!important }
    .h1r483::before, .b62125::before{ content: "" }
    .h1r483::before, .b62125::before, .zk98z9 img, .k3039j img, .ub6472 img, .s33610 img, .s33610{ width: 100%!important }
    .we012g, .zk98z9 img, .k3039j, .k3039j img, .ub6472, .ub6472 img, .te668i iframe, .te668i, .s33610 img, .s33610, .t90i22{ height: auto!important }
    .h1r483::before, .b62125::before{ padding-top: 58%!important }
    .y8017f{ box-shadow: none!important }
    .x09f37, .t2f180, .n2017b, .c8g6r0{ max-width: 100%!important }
    .v986gy, .t90i22, .a82g3r, .y8017f, .we012g{ margin: 15px 0 0 0!important }
    .e80c17{ margin: 15px 0 10px 0!important }
    .t2f180, .x57506, .n2017b, .u97139{ margin: 10px 0 0 0!important }
    .t2f180{ font-size: 16px!important }
    .x09f37, .e9l174, .y6rh29{ font-size: 18px!important }
    .x09f37{ line-height: 26px!important }
    .x09f37, .c8g6r0, .h3t02u{ overflow: hidden }
    .x09f37, .c8g6r0, .h3t02u{ white-space: nowrap }
    .x09f37, .c8g6r0, .h3t02u{ text-overflow: ellipsis }
    .t2f180{ line-height: 26px!important }
    .x57506{ height: 48px!important }
    .x57506{ border-bottom: 1px solid var(--Web-O9FU61) }
    .y3vo86, .m0p16l{ line-height: 48px!important }
    .b62125::before{ padding-top: 100%!important }
    .zk98z9{ width: 44px!important }
    .zk98z9{ height: 44px!important }
    .zk98z9 img{ width: 22px!important }
    .zk98z9 img{ height: 22px!important }
    .h2314u{ margin: 15px 0 0 0!important }
    .z752vl .row .col-md-3{ width: 50%!important }
    .c0046v{ margin: 10px 0 0 0!important }
    .c0046v, .n1945q, .y5x1g0{ height: auto!important }
    .c0046v::before{ height: auto!important }
    .q26962, .d8t671{ font-size: 19px!important }
    .q26962, .d8t671{ text-align: center!important }
    .d8t671{ line-height: 27px!important }
    .d8t671{ margin: 4px 0 0 0!important }
    .c0046v{ background: transparent!important }
    .c0046v::before, .q26962, .d8t671, .r66d51, .n1945q{ display: none!important }
    .p594hm{ padding-left: 0!important }
    .p594hm{ padding-right: 0!important }
    .n9v17b{ font-size: 17px!important }
    .w9z2e0{ margin: 6px 0 0 0!important }
    .w9z2e0{ font-size: 15px!important }
    .y5x1g0{ padding: 0!important }
    .y5x1g0{ margin: 6px 0 0 0!important }
    .k43h8o{ font-size: 18px!important }
    .q6510h{ padding: 10px 0 0 0!important }
    .e2lm99, .h3t02u{ font-size: 16px!important }
    .e2lm99{ line-height: 25px!important }
    .ss1j85{ margin: 6px 0 0 0!important }
    .dxq251 .row .col-md-4{ width: 50%!important }
    .te668i{ height: auto!important }
    .te668i iframe{ height: auto!important }
    .s624xi{ font-size: 18px!important }
    .r3u8c9{ font-size: 15px!important }
    .r3u8c9{ line-height: 23px!important }
    .nxl288{ padding: 20px!important }
    .s3704x, .af8k72{ margin: 14px 0 0 0!important }
    .h84059{ margin: 14px 0!important }
    .te668i, .nxl288{ margin: 10px 0 0 0!important }
    .r3u8c9{ margin: 8px 0 0 0!important }
    .u763kh{ line-height: 1!important }
    .p53us8 .row .col-md-3{ width: 50%!important }
    .n2017b{ line-height: 28px!important }
    .u97139{ margin: 6px 0 10px 0!important }
    .n454f7{ border: 0!important }
    .ja557z{ margin: 15px 0 0 0!important }
    .r3u8c9{ display: none!important }
    .s624xi{ margin: 15px 0 8px 0!important }
    .p750g9{ font-size: 17.5px!important }
    .p750g9{ overflow: hidden!important }
    .p750g9{ text-overflow: ellipsis!important }
    .p750g9{ white-space: nowrap!important }
    .q98p51{ height: auto!important }
    .q98p51{ float: none!important }
    .q98p51{ display: block!important }
    .q98p51::before{ padding-top: 100%!important }
    .q98p51::before{ width: 100%!important }
    .q98p51::before{ display: block!important }
    .q98p51::before{ content: "" }
    .oi4351{ text-align: center!important }
    .oi4351{ font-size: 15px!important }
    .e22q94{ height: 60px!important }
    .z272kl li span{ overflow: hidden }
    .z272kl li span{ white-space: nowrap }
    .z272kl li span{ text-overflow: ellipsis }
    .nv5q43{ margin: 10px 0 0 0!important }
    .nv5q43 img{ width: 100% }
    .nv5q43 img{ height: auto }
    .i02f1u{ margin: 10px 0 0 0!important }
    .i02f1u h6{ text-align: center!important }
    .i02f1u h6{ font-size: 17px!important }
    .i02f1u p{ text-align: center!important }
    .i02f1u p{ font-size: 16px!important }
    .i02f1u{ padding: 0!important }
    .aq6402{ font-size: 16px!important }
    .g942i2, .aq6402{ line-height: 26px!important }
    .pa789b{ height: auto!important }
    .pa789b{ padding: 30px 20px!important }
    .aq6402{ margin: 6px 0 0 0!important }
    .q664y3{ margin: 0!important }
    .kn581v, .j229ex, .hb0975{ margin: 14px 0 0 0!important }
    .ph6e87{ margin: 14px 0 0 0!important }
    .o6l4h3{ margin-bottom: 0!important }
    .g942i2{ font-size: 17.5px!important }
    .f1j45n{ height: 35px!important }
    .z272kl li span{ margin-left: 4px!important }
    .z272kl li span{ margin-right: 4px!important }
    .o6l4h3 .row .col-md-2{ width: 33.33%!important }
    .j111qi{ height: auto!important }
    .j111qi img, .h5162l{ width: 100%!important }
    .j111qi img, .h5162l{ height: auto!important }
    .j111qi{ padding: 25px 20px!important }
    .h5162l{ background: transparent!important }
    .x0j96v{ line-height: 20px!important }
    .x0j96v{ overflow: hidden }
    .x0j96v{ text-overflow: ellipsis }
    .x0j96v{ white-space: nowrap }
    .nf25l0{ padding: 0!important }
    .j111qi{ margin: 15px 0 0 0!important }
    .o6l4h3{ margin-top: 0!important }
    .v5x9d3{ padding-bottom: 29%!important }
    .v5x9d3, .r5ta95{ float: none!important }
    .v5x9d3, .r5ta95{ display: block!important }
    .v5x9d3{ margin: 10px 0 5px 0!important }
    .r5ta95, .h9514u, .o3164p, .q7j578, .p3i5n5, .m65434, .z19w39, .oap905, .i501tu{ height: 100%!important }
    .r5ta95, .h9514u, .o3164p, .q7j578, .p3i5n5, .m65434, .z19w39, .oap905, .i501tu{ position: absolute!important }
    .gx003r{ font-weight: 600!important }
    .gx003r{ font-size: 17px!important }
    .d233cz{ font-size: 16px!important }
    .d233cz{ line-height: 22px!important }
    .mo943y{ justify-content: center!important }
    .mo943y{ padding: 0 20px!important }
    .o6l4h3{ margin-bottom: 0!important }
    .v26po1, .v188zj{ width: 30px!important }
    .v26po1, .v188zj{ height: 30px!important }
    .v26po1{ line-height: 32px!important }
    .v188zj{ line-height: 30px!important }
    .v26po1 svg{ width: 16px!important }
    .v26po1 svg{ height: 16px!important }
    .v26po1 svg{ margin: 1px 0 0 1px!important }
    .v188zj svg{ width: 17px!important }
    .v188zj svg{ height: 17px!important }
    .q3o8y5{ padding: 0 20px!important }
    .h4mz49{ font-size: 16px!important }
    .h4mz49{ overflow: hidden }
    .h4mz49{ white-space: nowrap }
    .h4mz49{ text-overflow: ellipsis }
    .h804q5{ padding: 0!important }
    .r5163w{ padding: 0!important }
    .e445c3{ padding: 0 20px!important }
    .ja1e68{ max-width: 50%!important }
    .ja1e68{ overflow: hidden!important }
    .ja1e68{ text-overflow: ellipsis!important }
    .ja1e68{ white-space: nowrap!important }
    .gl6479{ border-right: 0!important }
    .gl6479{ border-bottom: 1px solid var(--Web-O9FU61) }
    .gl6479{ padding-bottom: 5px!important }
    .t0i4b0{ padding: 20px 0!important }
    .x6r112{ padding: 0 20px!important }
    .i3549y{ font-size: 15.5px!important }
    .w2966t, .a255yx{ width: 30px!important }
    .w2966t, .a255yx{ height: 30px!important }
    .a255yx svg, .w2966t svg{ width: 16px!important }
    .a255yx svg, .w2966t svg{ height: 16px!important }
    .a255yx{ margin: 0 -10px 40px 0!important }
    .w2966t{ margin: 0 0 40px -10px!important }
    .z272kl li:nth-child(4), .z272kl li:nth-child(5), .z272kl li:nth-child(6), .h7361i{ display: none!important }
    .of320a{ width: 36px!important }
    .of320a{ height: 36px!important }
    .of320a svg, .wqq462 svg{ width: 17px!important }
    .of320a svg, .wqq462 svg{ height: 17px!important }
    .of320a svg{ margin: 0!important }
    .m8934e, .g0h616, .w28f14, .i03i16{ margin: 14px 0!important }
    .uh95c8, .k51ma6, .fmb269, .t35d8f, .w28f14 h2, .w28f14 p, .a3158z, .t35d8f::before, .fmb269::before, .k51ma6::before, .x1nl29, .vk563m{ font-size: 17px!important }
    .o5m9z5, .w28f14 p{ margin: 4px 0 0 0!important }
    .a3158z{ margin: 15px 0 0 0!important }
    .y69i7d{ margin: 8px 0 0 0!important }
    .w28f14 h2, .w28f14 p{ text-align: start!important }
    .w28f14 p{ padding: 4px 0 0 0!important }
    .a3158z::before{ width: 18px!important }
    .a3158z::before{ height: 18px!important }
    .m8934e{ height: auto!important }
    .r44t4q{ height: 50px!important }
    .d97g9t{ line-height: 50px!important }
    .b40pk8, .v013zp{ margin-bottom: 0!important }
    .i1316u{ height: auto!important }
    .i1316u{ background: transparent!important }
    .i1316u img{ width: 100% }
    .i1316u img{ height: auto }
    .d261wo .row .col-md-3{ width: 50%!important }
    .pa789b{ margin: 15px 0 0 0!important }
    .q98p51{ margin: 0!important }
    .zi8021{ padding-left: 0!important }
    .zi8021{ padding-right: 0!important }
    .go895o{ overflow: hidden }
    .go895o{ white-space: nowrap }
    .go895o{ text-overflow: ellipsis }
    .zk286n{ margin: 14px 0 80px 0!important }
}
@media (min-width:768px) and (max-width:991px){
    main{ margin-bottom: 105px!important }
    .x3287n, .g67d7f, .obbx5v, .db75du, .x4vs5d, .r4z6vm{ display: none }
    .x80247, .z9e62w, .a82g3r, .e9l174{ display: block!important }
    .h50iu1{ font-size: 15.5px!important }
    .tz9913 svg{ width: 19.5px!important }
    .tz9913 svg{ height: 19.5px!important }
    .b03th6{ font-size: 17px!important }
    .x09f37, .t2f180{ display: none!important }
    .h1r483, .b62125, .h1r483::before, .b62125::before{ display: block!important }
    .h1r483, .b62125{ float: none!important }
    .h1r483, .b62125{ height: auto!important }
    .h1r483::before, .b62125::before{ content: "" }
    .h1r483::before, .b62125::before, .zk98z9 img, .k3039j img, .ub6472 img, .s33610 img, .s33610{ width: 100%!important }
    .zk98z9 img, .k3039j, .k3039j img, .ub6472, .ub6472 img, .te668i iframe, .te668i, .s33610 img, .s33610{ height: auto!important }
    .h1r483::before, .b62125::before{ padding-top: 100%!important }
    .y8017f{ box-shadow: none!important }
    .x09f37, .t2f180, .n2017b, .c8g6r0{ max-width: 100%!important }
    .v986gy, .e80c17, .t90i22, .a82g3r, .y8017f, .n2017b, .u97139{ margin: 12px 0 0 0!important }
    .t2f180, .x57506{ margin: 6px 0 0 0!important }
    .t2f180{ font-size: 16px!important }
    .x09f37, .e9l174{ font-size: 18px!important }
    .y6rh29{ font-size: 18px!important }
    .x09f37, .y6rh29, .n2017b{ line-height: 26px!important }
    .x09f37, .c8g6r0{ overflow: hidden }
    .x09f37, .c8g6r0{ white-space: nowrap }
    .x09f37, .c8g6r0{ text-overflow: ellipsis }
    .t2f180{ line-height: 26px!important }
    .x57506{ height: 48px!important }
    .x57506{ border-bottom: 1px solid var(--Web-O9FU61) }
    .y3vo86, .m0p16l{ line-height: 48px!important }
    .y3vo86, .m0p16l{ font-size: 16px!important }
    .b62125::before{ padding-top: 100%!important }
    .we012g{ height: 100%!important }
    .zk98z9{ width: 44px!important }
    .zk98z9{ height: 44px!important }
    .zk98z9 img{ width: 22px!important }
    .zk98z9 img{ height: 22px!important }
    .z752vl .row .col-md-3{ width: 50%!important }
    .z9e62w{ z-index: 999999!important }
    .z9e62w, .c0046v{ background: #FFFFFF }
    .q26962, .d8t671{ font-size: 19px!important }
    .n1945q{ height: 100%!important }
    .d8t671{ line-height: 29px!important }
    .c0046v{ height: auto!important }
    .n1945q, .c0046v::before{ display: none!important }
    .c0046v{ margin: 12px 0 0 0!important }
    .c0046v .row .col-md-6{ width: 100%!important }
    .p667l3{ margin: 14px 0 0 0!important }
    .p594hm, .y5x1g0{ padding-left: 0!important }
    .p594hm, .y5x1g0{ padding-right: 0!important }
    .k43h8o{ font-size: 18px!important }
    .q6510h{ padding: 10px 0 0 0!important }
    .e2lm99{ font-size: 16px!important }
    .e2lm99{ line-height: 27px!important }
    .ss1j85{ margin: 6px 0 0 0!important }
    .u763kh{ line-height: 1!important }
    .p1z96d .row .col-md-6{ width: 100%!important }
    .nxl288, .s624xi, .ja557z{ margin: 10px 0 0 0!important }
    .r3u8c9{ font-size: 16px!important }
    .r3u8c9{ line-height: 8px!important }
    .n454f7{ border: 0!important }
    .h3t02u, .ss1j85{ font-size: 16px!important }
    .n454f7{ padding: 18px!important }
    .te668i{ margin: 15px 0 0 0!important }
    .s624xi{ font-size: 18px!important }
    .p594hm{ padding-top: 15px!important }
    .zr41d4 .row .col-md-6{ width: 100%!important }
    .t90i22{ height: auto!important }
    .p53us8, .z752vl{ margin: 15px 0 0 0!important }
    .p53us8 .row .col-md-3{ width: 50%!important }
    .s0b6y6 .row .col-md-6{ width: 100%!important }
    .s3704x, .af8k72, .h84059{ margin: 18px 0 0 0!important }
    .p750g9{ font-size: 18px!important }
    .p750g9, .aq6402{ overflow: hidden!important }
    .p750g9, .aq6402{ text-overflow: ellipsis!important }
    .p750g9, .aq6402{ white-space: nowrap!important }
    .q98p51{ height: auto!important }
    .q98p51{ float: none!important }
    .q98p51{ display: block!important }
    .q98p51::before{ padding-top: 100%!important }
    .q98p51::before{ width: 100%!important }
    .q98p51::before{ display: block!important }
    .q98p51::before{ content: "" }
    .oi4351{ text-align: center!important }
    .oi4351{ font-size: 15px!important }
    .e22q94{ height: 60px!important }
    .z272kl li span{ overflow: hidden }
    .z272kl li span{ white-space: nowrap }
    .z272kl li span{ text-overflow: ellipsis }
    .nv5q43{ margin: 10px 0 0 0!important }
    .nv5q43 img{ width: 100% }
    .nv5q43 img{ height: auto }
    .i02f1u{ margin: 10px 0 0 0!important }
    .i02f1u h6{ text-align: center!important }
    .i02f1u h6{ font-size: 18px!important }
    .i02f1u p{ text-align: center!important }
    .i02f1u p{ font-size: 17px!important }
    .i02f1u{ padding: 0!important }
    .o6l4h3 .row .col-md-6{ width: 100%!important }
    .o6l4h3{ margin-bottom: 25px!important }
    .pa789b{ height: auto!important }
    .q664y3{ margin: 0!important }
    .kn581v, .j229ex, .hb0975{ margin: 14px 0 0 0!important }
    .o6l4h3{ margin-bottom: 20px!important }
    .f1j45n{ height: 35px!important }
    .z272kl li span{ margin-left: 4px!important }
    .z272kl li span{ margin-right: 4px!important }
    .o6l4h3 .row .col-md-2{ width: 20%!important }
    .j111qi{ height: auto!important }
    .j111qi img, .h5162l{ width: 100%!important }
    .j111qi img, .h5162l{ height: auto!important }
    .j111qi{ padding: 25px 20px!important }
    .h5162l{ background: transparent!important }
    .x0j96v{ line-height: 20px!important }
    .x0j96v{ overflow: hidden }
    .x0j96v{ text-overflow: ellipsis }
    .x0j96v{ white-space: nowrap }
    .nf25l0{ padding: 0!important }
    .j111qi{ margin: 15px 0 0 0!important }
    .o6l4h3{ margin-top: 0!important }
    .o6l4h3 .row .col-md-4{ width: 50%!important }
    .v5x9d3{ padding-bottom: 30%!important }
    .v5x9d3, .r5ta95{ float: none!important }
    .v5x9d3, .r5ta95{ display: block!important }
    .v5x9d3{ margin: 10px 0 5px 0!important }
    .r5ta95, .h9514u, .o3164p, .q7j578, .p3i5n5, .m65434, .z19w39, .oap905, .i501tu{ height: 100%!important }
    .r5ta95, .h9514u, .o3164p, .q7j578, .p3i5n5, .m65434, .z19w39, .oap905, .i501tu{ position: absolute!important }
    .gx003r, .d233cz{ width: auto!important }
    .gx003r{ font-weight: 600!important }
    .gx003r{ font-size: 16px!important }
    .d233cz{ font-size: 15px!important }
    .d233cz{ line-height: 22px!important }
    .mo943y{ justify-content: space-between!important }
    .mo943y{ flex-direction: row!important }
    .mo943y{ padding: 0 20px!important }
    .gx003r{ max-width: 50%!important }
    .gx003r{ overflow: hidden }
    .gx003r{ text-overflow: ellipsis }
    .gx003r{ white-space: nowrap }
    .v26po1, .v188zj{ width: 33px!important }
    .v26po1, .v188zj{ height: 33px!important }
    .v26po1{ line-height: 32px!important }
    .v188zj{ line-height: 30px!important }
    .v26po1 svg{ width: 17px!important }
    .v26po1 svg{ height: 17px!important }
    .v26po1 svg{ margin: 1px 0 0 1px!important }
    .v188zj svg{ width: 18px!important }
    .v188zj svg{ height: 18px!important }
    .q3o8y5{ padding: 0 20px!important }
    .h4mz49{ font-size: 17px!important }
    .h4mz49{ overflow: hidden }
    .h4mz49{ white-space: nowrap }
    .h4mz49{ text-overflow: ellipsis }
    .h804q5{ padding: 0!important }
    .r5163w{ padding: 0!important }
    .e445c3{ padding: 0 20px!important }
    .ja1e68{ max-width: 50%!important }
    .ja1e68{ overflow: hidden!important }
    .ja1e68{ text-overflow: ellipsis!important }
    .ja1e68{ white-space: nowrap!important }
    .gl6479{ border-right: 0!important }
    .gl6479{ border-bottom: 1px solid var(--Web-O9FU61) }
    .gl6479{ padding-bottom: 5px!important }
    .g537rv .row .col-md-3{ width: 35%!important }
    .g537rv .row .col-md-9{ width: 65%!important }
    .t0i4b0{ padding: 20px 0!important }
    .x6r112{ padding: 0 20px!important }
    .i3549y{ font-size: 15px!important }
    .w2966t, .a255yx{ width: 30px!important }
    .w2966t, .a255yx{ height: 30px!important }
    .a255yx svg, .w2966t svg{ width: 16px!important }
    .a255yx svg, .w2966t svg{ height: 16px!important }
    .a255yx{ margin: 0 -15px 10px 0!important }
    .w2966t{ margin: 0 0 10px -15px!important }
    .z272kl li:nth-child(4), .z272kl li:nth-child(5), .z272kl li:nth-child(6), .h7361i{ display: none!important }
    .of320a{ width: 36px!important }
    .of320a{ height: 36px!important }
    .of320a svg, .wqq462 svg{ width: 17px!important }
    .of320a svg, .wqq462 svg{ height: 17px!important }
    .of320a svg{ margin: 0!important }
    .m8934e, .g0h616, .w28f14, .i03i16{ margin: 14px 0!important }
    .uh95c8, .k51ma6, .fmb269, .t35d8f, .w28f14 h2, .w28f14 p, .a3158z, .t35d8f::before, .fmb269::before, .k51ma6::before, .x1nl29, .vk563m{ font-size: 17px!important }
    .o5m9z5, .w28f14 p{ margin: 8px 0 0 0!important }
    .a3158z{ margin: 15px 0 0 0!important }
    .y69i7d{ margin: 12px 0 0 0!important }
    .w28f14 p{ padding: 4px 0 0 0!important }
    .a3158z::before{ width: 18px!important }
    .a3158z::before{ height: 18px!important }
    .m8934e{ height: auto!important }
    .r44t4q{ height: 50px!important }
    .d97g9t{ line-height: 50px!important }
    .b40pk8, .v013zp{ margin-bottom: 0!important }
    .i1316u{ height: auto!important }
    .i1316u{ background: transparent!important }
    .i1316u img{ width: 100% }
    .i1316u img{ height: auto }
    .d261wo .row .col-md-3{ width: 25%!important }
    .i03i16 .row .col-md-4{ width: 50%!important }
    .b40pk8 .row .col-md-6{ width: 100%!important }
    .w1811y{ overflow: hidden }
    .w1811y{ white-space: nowrap }
    .w1811y{ text-overflow: ellipsis }
    .pa789b{ margin: 10px 0 0 0!important }
    .ph6e87{ margin: 14px 0 0 0!important }
    .zk286n{ margin: 14px 0 80px 0!important }
}
@media (min-width:992px) and (max-width:1199px){
    .m96l9m{ font-size: 17px!important }
    .f61770 ul li:first-child{ display: none!important }
    .f61770 ul li:nth-child(2) a{ padding-left: 0!important }
    .f61770{ height: 56px!important }
    .k27wk6 svg{ width: 18px!important }
    .k27wk6 svg{ height: 18px!important }
    .i123i7{ font-size: 14.5px!important }
    .f630xx, .h5zzvk li a, .e7v3f0{ overflow: hidden }
    .f630xx, .h5zzvk li a, .e7v3f0{ white-space: nowrap }
    .f630xx, .h5zzvk li a, .e7v3f0{ text-overflow: ellipsis }
    .o6v2y8, .p268gl, .w9b4zl{ width: 40px!important }
    .o6v2y8, .p268gl, .w9b4zl{ height: 40px!important }
    .o6v2y8 svg, .p268gl svg, .w9b4zl svg{ width: 20px!important }
    .o6v2y8 svg, .p268gl svg, .w9b4zl svg{ height: 20px!important }
    .e7v3f0, .d48yli li a{ font-size: 15px!important }
    .pir95i li a{ font-size: 13px!important }
    .pir95i li a, .c8g6r0, .d8t671, .r66d51{ overflow: hidden }
    .pir95i li a, .c8g6r0, .d8t671, .r66d51{ white-space: nowrap }
    .pir95i li a, .c8g6r0, .d8t671, .r66d51{ text-overflow: ellipsis }
    .pir95i li a{ max-width: 125px!important }
    .pir95i{ display: flex }
    .pir95i{ justify-content: space-between }
    .pir95i{ align-items: center }
    .s33610 img, .k3039j img{ width: 100% }
    .s33610 img, .k3039j img{ height: auto }
    .ub6472, .k3039j{ height: auto!important }
    .ub6472{ background: transparent!important }
    .h1r483, .b62125{ height: auto!important }
    .h1r483, .b62125{ float: none!important }
    .h1r483, .b62125{ display: block!important }
    .h1r483::before, .b62125::before{ padding-top: 100%!important }
    .h1r483::before, .b62125::before{ width: 100%!important }
    .h1r483::before, .b62125::before{ display: block!important }
    .h1r483::before, .b62125::before{ content: "" }
    .x09f37{ max-width: 100%!important }
    .x09f37, .y6rh29, .d8t671, .q26962{ font-size: 20px!important }
    .t2f180, .n2017b{ line-height: 26px!important }
    .we012g{ height: 100%!important }
    .zk98z9{ width: 42px!important }
    .zk98z9{ height: 42px!important }
    .zk98z9 img{ width: 21px!important }
    .zk98z9 img{ height: 21px!important }
    .c8g6r0{ line-height: 42px!important }
    .c8g6r0{ max-width: 100%!important }
    .d8t671{ line-height: 30px!important }
    .r66d51{ font-size: 16px!important }
    .n1945q{ height: 100%!important }
    .c0046v{ height: auto!important }
    .y5x1g0{ height: 35px!important }
    .r3u8c9{ font-size: 15px!important }
    .r3u8c9{ line-height: 5px!important }
    .s624xi{ font-size: 19px!important }
    .p750g9{ font-size: 18px!important }
    .p750g9{ overflow: hidden!important }
    .p750g9{ text-overflow: ellipsis!important }
    .p750g9{ white-space: nowrap!important }
    .q98p51{ height: auto!important }
    .q98p51{ float: none!important }
    .q98p51{ display: block!important }
    .q98p51::before{ padding-top: 100%!important }
    .q98p51::before{ width: 100%!important }
    .q98p51::before{ display: block!important }
    .q98p51::before{ content: "" }
    .oi4351{ text-align: center!important }
    .oi4351{ font-size: 15px!important }
    .e22q94{ height: 70px!important }
    .z272kl li span, .aq6402{ overflow: hidden }
    .z272kl li span, .aq6402{ white-space: nowrap }
    .z272kl li span, .aq6402{ text-overflow: ellipsis }
    .nv5q43{ margin: 10px 0 0 0!important }
    .nv5q43 img{ width: 100% }
    .nv5q43 img{ height: auto }
    .i02f1u{ margin: 10px 0 0 0!important }
    .i02f1u h6{ text-align: center!important }
    .i02f1u h6{ font-size: 18px!important }
    .i02f1u p{ text-align: center!important }
    .i02f1u p{ font-size: 17px!important }
    .i02f1u{ padding: 0!important }
    .o6l4h3{ margin-bottom: 25px!important }
    .g942i2{ font-size: 19px!important }
    .aq6402{ line-height: 13px!important }
    .aq6402{ margin: 13px 0 0 0!important }
    .v5x9d3{ padding-bottom: 30%!important }
    .v5x9d3, .r5ta95{ float: none!important }
    .v5x9d3, .r5ta95{ display: block!important }
    .v5x9d3{ margin: 10px 0 5px 0!important }
    .r5ta95, .h9514u, .o3164p, .q7j578, .p3i5n5, .m65434, .z19w39, .oap905, .i501tu{ height: 100%!important }
    .r5ta95, .h9514u, .o3164p, .q7j578, .p3i5n5, .m65434, .z19w39, .oap905, .i501tu{ position: absolute!important }
    .gx003r, .d233cz{ width: auto!important }
    .gx003r{ font-weight: 600!important }
    .gx003r{ font-size: 16px!important }
    .d233cz{ font-size: 15px!important }
    .d233cz{ line-height: 22px!important }
    .mo943y{ justify-content: space-between!important }
    .mo943y{ flex-direction: row!important }
    .mo943y{ padding: 0 20px!important }
    .gx003r{ max-width: 50%!important }
    .gx003r{ overflow: hidden }
    .gx003r{ text-overflow: ellipsis }
    .gx003r{ white-space: nowrap }
    .h804q5{ padding: 0!important }
    .r5163w{ padding: 0!important }
    .e445c3{ padding: 0 20px!important }
    .ja1e68{ max-width: 50%!important }
    .ja1e68{ overflow: hidden!important }
    .ja1e68{ text-overflow: ellipsis!important }
    .ja1e68{ white-space: nowrap!important }
    .gl6479{ border-right: 0!important }
    .gl6479{ border-bottom: 1px solid var(--Web-O9FU61) }
    .gl6479{ padding-bottom: 5px!important }
    .g537rv .row .col-md-3{ width: 30%!important }
    .g537rv .row .col-md-9{ width: 70%!important }
    .t0i4b0{ padding: 20px 0!important }
    .x6r112{ padding: 0 20px!important }
    .i3549y{ font-size: 15px!important }
    .w2966t, .a255yx{ width: 30px!important }
    .w2966t, .a255yx{ height: 30px!important }
    .a255yx svg, .w2966t svg{ width: 16px!important }
    .a255yx svg, .w2966t svg{ height: 16px!important }
    .a255yx{ margin: 0 -15px 10px 0!important }
    .w2966t{ margin: 0 0 10px -15px!important }
    .i1316u{ height: auto!important }
    .i1316u img{ width: 100%!important }
    .i1316u img{ height: auto!important }
    .uh95c8{ font-size: 19px!important }
    .o5m9z5{ margin: 5px 0 0 0!important }
    .w28f14 h2{ font-size: 18px!important }
    .w28f14 p{ text-align: left!important }
    .i03i16 .row .col-md-4{ width: 50%!important }
    .zi8021{ padding-left: 0!important }
    .zi8021{ padding-right: 0!important }
    .go895o{ overflow: hidden }
    .go895o{ white-space: nowrap }
    .go895o{ text-overflow: ellipsis }
}
@media (min-width: 1025px) and (max-width: 1280px) {

}
@media (min-width: 1366px) and (min-height: 768px) {

}